import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from "moment-timezone";
import {
  Modal, Pagination,
  Spin, NoData, Rate, Tooltip,
  Input, FormItem, Select2, Radio,
  Popconfirm, Button,
  SuccessNotification,
  DisclaimerBox, Alert,
} from 'components/common';
import {
  varOptions, varIs, varIsIn, varLabel, varKey,
} from 'common/var';
import { callGetApiWithAuth, callPutApiWithAuth } from 'utils/api';
import { asCurrencyPrice, asDateTimeAgo } from 'utils/text';
import {
  AiOutlineUp, AiOutlineDown, AiOutlineCloseCircle
} from "react-icons/ai";
import NoPhotoImg from 'assets/nophoto.svg';

export default function TapPage() {
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({
    pair_id: '',
    status: '',
    order_type: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAuto, setIsLoadingAuto] = useState(false)
  const [pairs, setPairs] = useState([])
  let checkInterval = undefined;

  const onSearchInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    })
  }
  const onGetPairs = ({ data }) => {
    setPairs(data.pairs);
  }
  const onGetTableData = ({ data }) => {
    setTableData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
    setIsLoading(false)
    setIsLoadingAuto(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
    setIsLoadingAuto(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params).map(k => k + '=' + params[k]).join('&');
    setIsLoading(true)
    callGetApiWithAuth(`akashx/tap/list?${q}`,
      onGetTableData, onFailTableData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const handleReload = () => {
    loadTableData(paginationParam, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onCheckDone = () => {
    const params = {
      ...searchParam,
      page: paginationParam.currentPage,
      per_page: paginationParam.perPage,
    };
    const q = Object.keys(params).map(k => k + '=' + params[k]).join('&');
    callGetApiWithAuth(`akashx/tap/list?${q}`,
      onGetTableData, onFailTableData
    )
  }
  const onCheckFail = () => {
    setIsLoadingAuto(false)
  }
  const checkPendings = () => {
    if (isLoading || isLoadingAuto) return;

    setIsLoadingAuto(true)
    callGetApiWithAuth(`akashx/tap/check_pendings`, onCheckDone, onCheckFail)
  }

  useEffect(() => {
    handleSearch()
    callGetApiWithAuth(`akashx/tap/filters`, onGetPairs)
    checkInterval = setInterval(() => {
      checkPendings();
    }, [10000]);

    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [])

  return (
    <Wrapper>
      <div className='top-root'>
        <div className='title-wrap'>
          &nbsp;
        </div>
        <div className='search-root'>
          {/* <Select2 value={searchParam.pair_id}
            onChange={e=>onSearchInput('pair_id', e.target.value)}
            options={[
              { label: 'Select Pair', value: '' },
              ...pairs.map(el => ({
                label: el.name, value: el.id
              }))
            ]}
            style={{ minWidth: 200 }}
          />
          <Select2 value={searchParam.order_type}
            onChange={e=>onSearchInput('order_type', e.target.value)}
            options={[
              { label: 'Select Order', value: '' },
              ...varOptions('akaTradeTap.orderType')
            ]}
            style={{ minWidth: 200 }}
          />
          <button className='btn-search'
            onClick={handleSearch}
          >
            Search
          </button> */}
          &nbsp;
        </div>

      </div>
      {/* <div style={{ height: 24 }} />
      <Alert type="warning" showIcon
        message={'Metaquotes made updates that caused MT5 connectivity issues. We are under maintenance until things are back online. Check out the trade alerts and live sessions to catch some trades!'}
      />
      <div style={{ height: 24 }} /> */}
      <div className='table-wrap'>
        {tableData.map(el => (
          <ItemCard data={el}
            handleReload={handleReload}
          />
        ))}
        {isLoading &&
          <div className='spin-wrap'>
            <Spin spinning={true} />
          </div>}
        {(!isLoading && tableData.length == 0) &&
          <div className='empty-wrap'>
            <NoData />
          </div>
        }
      </div>
      <div className='pagination-wrap'>
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
      <div style={{ height: 24 }} />
      <DisclaimerBox />
    </Wrapper>
  )
}

const ItemCard = ({ data, ...props }) => {
  const [modalMode, setModalMode] = useState(undefined)
  const [expireHr, setExpireHr] = useState(0)
  const [expireMi, setExpireMi] = useState(0)
  const [expireSe, setExpireSe] = useState(0)
  const [expireTse, setExpireTse] = useState(0)
  const [isDeclineDoing, setIsDeclineDoing] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  let expireInterval = undefined;

  const calcExpireCount = () => {
    const endTime0 = moment
      .tz(
        `${data.expire_at}`,
        "YYYY-MM-DD HH:mm:ss",
        "America/Los_Angeles"
      )
      .local();
    const expireHr0 = endTime0.diff(moment().clone(), "hours");
    const expireMi0 = endTime0.diff(moment().clone(), "minutes") % 60;
    const expireSe0 = endTime0.diff(moment().clone(), "seconds") % 60;
    const expireTse0 = endTime0.diff(moment().clone(), "seconds");
    if (expireTse0 >= 0) {
      setExpireHr((expireHr0 < 10 ? '0' : '') + expireHr0)
      setExpireMi((expireMi0 < 10 ? '0' : '') + expireMi0)
      setExpireSe((expireSe0 < 10 ? '0' : '') + expireSe0)
      setExpireTse(expireTse0)
    }
  };

  const onCloseDone = (res) => {
    SuccessNotification(res.message)
    setIsDeclineDoing(false)
    props.handleReload()
  }
  const onCloseFail = () => {
    setIsDeclineDoing(false)
  }
  const handleClose = () => {
    setIsDeclineDoing(true)
    callPutApiWithAuth(
      `akashx/tap/${data.id}/close`, {},
      onCloseDone, onCloseFail
    )
  }

  useEffect(() => {
    if (data.expire_at) {
      calcExpireCount();
      expireInterval = setInterval(() => {
        calcExpireCount();
      }, [1000]);
    }

    return () => {
      if (expireInterval) {
        clearInterval(expireInterval);
      }
    };
  }, [data]);

  return (
    <div className='card-wrap'>
      {(data.action ||
        varIsIn('akaTradeTap.status', data.status, [
          'expired', 'closed'
        ])
      ) &&
        <div
          className={`stat-wrap
          ${(data.action && varIsIn('akaTapAction.status',
            data.action.status,
            ['opened', 'pending_update']
          )) ? 'active'
              : (data.action && varIsIn('akaTapAction.status',
                data.action.status,
                ['pending_open']
              )) ? 'pending'
                : 'inactive'}
        `}
        >
          {data.action
            ? varLabel('akaTapAction.status', data.action.status)
            : varIsIn('akaTradeTap.status', data.status, [
              'expired', 'closed'
            ])
              ? 'Expired'
              : ''}
        </div>}
      <div className='ma-wrap'>
        <div className='mal-wrap'>
          {data.educator &&
            <div className='edu-wrap'>
              <img src={data.educator.image || NoPhotoImg} />
              <p>
                {data.educator.first_name}&nbsp;
                {data.educator.last_name}
              </p>
            </div>}
          {data.pair &&
            <div className='pa-wrap'>
              <p className='lbl'>Pair</p>
              <p className={`span ${varKey('akaTradeTap.orderType', data.order_type)}`}>
                {data.pair.image &&
                  <img src={data.pair.image} />
                }
                <span>{data.pair.name}</span>
              </p>
            </div>}
          <div className='pa-wrap type-wrap'>
            <p className='lbl'>Type</p>
            <p className={`span ${varKey('akaTradeTap.orderType', data.order_type)}`}>
              {varLabel('akaTradeTap.orderType', data.order_type).toUpperCase()}
            </p>
          </div>
          <div className='pa-wrap'>
            <p className='lbl'>Entry Price</p>
            <p className='span'>
              {(data.action &&
                varIs('akaTapAction.status', data.action.status, 'opened')
              ) ? data.action.entry_point
                : data
                  ? data.entry_point
                  : '-'}
            </p>
          </div>
          <div className='pa-wrap'>
            <p className='lbl'>Stop Loss</p>
            <p className='span'>
              {(data.action &&
                varIs('akaTapAction.status', data.action.status, 'opened')
              ) ? data.action.stop_loss
                : data
                  ? data.stop_loss
                  : '-'}
            </p>
          </div>
          <div className='pa-wrap'>
            <p className='lbl'>Take Profit</p>
            <p className='span'>
              {(data.action &&
                varIs('akaTapAction.status', data.action.status, 'opened')
              ) ? data.action.tp1
                : data
                  ? data.tp1
                  : '-'}
            </p>
          </div>
          {data.expire_at &&
            <div className='pa-wrap exp-pa-wrap'>
              <p className='lbl'>Trade Expiration</p>
              <div className={`exp-wrap ${expireTse < 60 ? 'inactive' : ''}`}>
                <div className='exphr-wrap'>
                  <label>
                    {expireHr}
                  </label>
                  <span>hr</span>
                </div>
                <label>:</label>
                <div className='exphr-wrap'>
                  <label>
                    {expireMi}
                  </label>
                  <span>min</span>
                </div>
                <label>:</label>
                <div className='exphr-wrap'>
                  <label>
                    {expireSe}
                  </label>
                  <span>sec</span>
                </div>
              </div>
            </div>}
        </div>
        <div className='mar-wrap'>
          <div className='tact-wrap'>
            {(
              varIs('akaTradeTap.status', data.status, 'opened') &&
              !(
                data.action &&
                varIsIn('akaTapAction.status', data.action.status, [
                  'opened', 'pending_update', 'pending_open'
                ])
              ) &&
              expireTse > 0
            ) &&
              <ApproveBtn data={data}
                handleReload={props.handleReload}
              />}
            {/*
            (
              varIs('akaTradeTap.status', data.status, 'opened') && 
              (
                data.action && 
                varIsIn('akaTapAction.status', data.action.status, [
                  'opened', 
                ])
              ) && 
              expireTse>0 
            ) && 
            <UpdatePriceBtn data={data}
              handleReload={props.handleReload} 
            />
            */}
            {/*
            (
              varIs('akaTradeTap.status', data.status, 'opened') && 
              (
                data.action && 
                varIsIn('akaTapAction.status', data.action.status, [
                  'opened', 'pending_update', 'pending_open'
                ])
              )
            ) && 
            <Popconfirm onConfirm={handleClose}>
              <Button loading={isDeclineDoing}>
                Close
              </Button>
              </Popconfirm>*/}
          </div>
          {/* <div className='bact-wrap'>
            <Button className='more-btn'
              onClick={() => setIsShowMore(!isShowMore)}
            >
              More Details&nbsp;
              {isShowMore 
              ? <AiOutlineUp />
              : <AiOutlineDown />}
            </Button>
          </div> */}
        </div>
      </div>
      {isShowMore &&
        <div className='mo-wrap'>
          <div className='ty-wrap'>
            <label>
              Strength:&nbsp;
            </label>
            <Rate allowHalf disabled value={data.strength} />
          </div>
          <div className='ty-wrap'>
            <label>
              Created:&nbsp;
            </label>
            <span>
              {data.time_since_listed}
            </span>
          </div>
          <div className='note-wrap'>
            <label>Notes:</label>
            {data.chat_image &&
              <div className='note-img'>
                <img src={data.chat_image}
                  onClick={() => setModalMode('chat_image')}
                />
              </div>}
            <p dangerouslySetInnerHTML={{ __html: data.note }} />
          </div>
        </div>}
      {modalMode === 'chat_image' &&
        <Modal width={1024}
          closeIcon={(
            <div style={{
              color: '#EFEDF9',
              fontSize: 32,
              position: 'absolute',
              right: '-6px', top: '-16px'
            }}>
              <AiOutlineCloseCircle />
            </div>
          )}
          onCancel={() => setModalMode(undefined)}
          bodyStyle={{
            padding: 0
          }}
        >
          <img src={data.chat_image}
            style={{ width: '100%' }}
            alt={'Chat'}
          />
        </Modal>}
    </div>
  )
}

const ApproveBtn = (props) => {
  const theme = useSelector(state => state.ui.theme)
  const [modalMode, setModalMode] = useState(undefined)
  const [isUpdating, setIsUpdating] = useState(false)
  const [formData, setFormData] = useState({
    entry_point: '',
    stop_loss: '',
    tp1: '',
    lots: '',
    slippage: '',
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [statData, setStatData] = useState({
    balance: 0,
    currency: 'USD',
  })
  const [expireHr, setExpireHr] = useState(0)
  const [expireMi, setExpireMi] = useState(0)
  const [expireSe, setExpireSe] = useState(0)
  const [expireTse, setExpireTse] = useState(0)
  const [isEditable, setIsEditable] = useState(false)
  const [pairInfo, setPairInfo] = useState(undefined)
  let expireInterval = undefined;

  const calcExpireCount = () => {
    const endTime0 = moment
      .tz(
        `${props.data.expire_at}`,
        "YYYY-MM-DD HH:mm:ss",
        "America/Los_Angeles"
      )
      .local();
    const expireHr0 = endTime0.diff(moment().clone(), "hours");
    const expireMi0 = endTime0.diff(moment().clone(), "minutes") % 60;
    const expireSe0 = endTime0.diff(moment().clone(), "seconds") % 60;
    const expireTse0 = endTime0.diff(moment().clone(), "seconds");
    if (expireTse0 >= 0) {
      setExpireHr((expireHr0 < 10 ? '0' : '') + expireHr0)
      setExpireMi((expireMi0 < 10 ? '0' : '') + expireMi0)
      setExpireSe((expireSe0 < 10 ? '0' : '') + expireSe0)
      setExpireTse(expireTse0)
    }
  };
  const onGetStatData = ({ data }) => {
    setStatData({
      ...statData,
      ...data,
    });
    setFormData(prev => ({
      ...prev,
      lots: Math.floor(Math.max(Math.floor(data.balance / props.data.pair.by_balance), 1) * props.data.pair.lot_rate * 100) / 100,
    }))
  };
  const getStatData = () => {
    callGetApiWithAuth(`akashx/home/stat`, onGetStatData);
  };

  const onGetPairInfo = ({ data }) => {
    setPairInfo(data)
    
  };
  const getPairInfo = () => {
    callGetApiWithAuth(`akashx/tap/${props.data.id}/live_price`, onGetPairInfo);
  }

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleReload()
    setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
    setModalMode(undefined)
  }
  const handleSubmit = () => {
    let errorMessages0 = [];
    [
      'entry_point', 'stop_loss', 'tp1',
      'lots',
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      } else if (isNaN(formData[k])) {
        errorMessages0.push({
          field: k,
          message: 'Input correct value'
        })
      } else if (formData[k] < 0) {
        errorMessages0.push({
          field: k,
          message: 'Input correct value'
        })
      }
    })
    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    setIsUpdating(true);
    const body = { ...formData };
    callPutApiWithAuth(
      `akashx/tap/${props.data.id}/approve`, body,
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    getStatData()
    getPairInfo()
  }, [])

  useEffect(() => {
    if (props.data.expire_at) {
      calcExpireCount();
      expireInterval = setInterval(() => {
        calcExpireCount();
      }, [1000]);
    }

    return () => {
      if (expireInterval) {
        clearInterval(expireInterval);
      }
    };
  }, [props.data]);

  useEffect(() => {
    let formData0 = { ...formData }
    if (props.data) {
      formData0['entry_point'] = props.data.entry_point;
      formData0['stop_loss'] = props.data.stop_loss;
      formData0['tp1'] = props.data.tp1;
      formData0['slippage'] = props.data.slippage;
    }
    setFormData(formData0)
  }, [props.data])

  return (
    <>
      <Button onClick={() => setModalMode('input')}>
        Approve
      </Button>
      {modalMode === 'input' &&
        <Modal width={460}
          onCancel={() => setModalMode(undefined)}
          closeIcon={(
            <div style={{
              color: theme === 'dark' ? '#EFEDF9' : '#585858',
              fontSize: 32,
              position: 'absolute',
              right: '-6px', top: '-16px'
            }}>
              <AiOutlineCloseCircle />
            </div>
          )}
        >
          <ApprWrap>
            <div className='pa-wrap'>
              {(props.data.pair && props.data.pair.image) &&
                <div className='pair-img'>
                  <img src={props.data.pair.image} />
                </div>
              }
              {(props.data.pair) &&
                <div className='pair-name'>
                  <span>{props.data.pair.name} </span>
                </div>
              }
              <div
                className={`type-span 
                  ${varKey('akaTradeTap.orderType', props.data.order_type)}
                `}
              >
                {varLabel('akaTradeTap.orderType', props.data.order_type).toUpperCase()}
              </div>
            </div>
            <div className='bal-wrap'>
              <h4>Account Balance:&nbsp;{asCurrencyPrice(statData.balance, statData.currency)}</h4>
            </div>
            <div className='ep-wrap'>
              <div className='ep-span'>
                <label>Entry Price</label>
                <span>{formData.entry_point}</span>
              </div>
              <div className='ep-span'>
                <label>Stop Loss</label>
                <span>{formData.stop_loss}</span>
              </div>
              <div className='ep-span'>
                <label>Take Profit</label>
                <span>{formData.tp1}</span>
              </div>
            </div>
            <div className='pair-info'>
              <div>
                <label>Live price:</label><span> {pairInfo?.live_price}</span>
              </div>
              <div>
                <label>Pips:</label><span> {pairInfo.pips.toFixed(4)}</span>
              </div>
              <div>
                <label>Created: </label><span> {asDateTimeAgo(props.data.created_at)}</span>
              </div>
            </div>
            <div className='bt-wrap'>
              {props.data.expire_at &&
                <div className={`exp-wrap ${expireTse < 60 ? 'inactive' : ''}`}>
                  <h4>Trade Expiration</h4>
                  <div className='exp-inwrap'>
                    <div className='exphr-wrap'>
                      <label>
                        {expireHr}
                      </label>
                      <span>hr</span>
                    </div>
                    <label>:</label>
                    <div className='exphr-wrap'>
                      <label>
                        {expireMi}
                      </label>
                      <span>min</span>
                    </div>
                    <label>:</label>
                    <div className='exphr-wrap'>
                      <label>
                        {expireSe}
                      </label>
                      <span>sec</span>
                    </div>
                  </div>
                </div>}

              <div className='inp-wrap'>
                <h4>Recommended LOT Size</h4>
                <div className='form-group'>
                  <Input value={formData.lots}
                    type={'number'}
                    onChange={e => {
                      if (e.target.value >= 0) {
                        setFormData({ ...formData, lots: e.target.value })
                      }
                    }}
                    disabled={!isEditable}
                    step={0.1}
                  />
                  {errorMessages.filter(el =>
                    el.field == 'lots'
                  ).map(el =>
                    <div className='err-wrap'>
                      {el.message}
                    </div>
                  )}
                  <div className='tog-wrap'
                    onClick={() => setIsEditable(true)}
                  >
                    Adjust Risk
                  </div>
                </div>
              </div>
            </div>
            <div className='act-wrap'>
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>
                  Approve
                </Button>
              </Popconfirm>
            </div>
          </ApprWrap>
        </Modal>
      }
    </>
  )
}

const UpdatePriceBtn = (props) => {
  const theme = useSelector(state => state)
  const [modalMode, setModalMode] = useState(undefined)
  const [isUpdating, setIsUpdating] = useState(false)
  const [formData, setFormData] = useState({
    command: 'modify_stop_loss',
    price: '',
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [statData, setStatData] = useState({
    balance: 0,
    currency: 'USD',
  })
  const [expireHr, setExpireHr] = useState(0)
  const [expireMi, setExpireMi] = useState(0)
  const [expireSe, setExpireSe] = useState(0)
  const [expireTse, setExpireTse] = useState(0)
  let expireInterval = undefined;

  const calcExpireCount = () => {
    const endTime0 = moment
      .tz(
        `${props.data.expire_at}`,
        "YYYY-MM-DD HH:mm:ss",
        "America/Los_Angeles"
      )
      .local();
    const expireHr0 = endTime0.diff(moment().clone(), "hours");
    const expireMi0 = endTime0.diff(moment().clone(), "minutes") % 60;
    const expireSe0 = endTime0.diff(moment().clone(), "seconds") % 60;
    const expireTse0 = endTime0.diff(moment().clone(), "seconds");
    if (expireTse0 >= 0) {
      setExpireHr((expireHr0 < 10 ? '0' : '') + expireHr0)
      setExpireMi((expireMi0 < 10 ? '0' : '') + expireMi0)
      setExpireSe((expireSe0 < 10 ? '0' : '') + expireSe0)
      setExpireTse(expireTse0)
    }
  };
  const onGetStatData = ({ data }) => {
    setStatData({
      ...statData,
      ...data,
    });
  };
  const getStatData = () => {
    callGetApiWithAuth(`akashx/home/stat`, onGetStatData);
  };

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleReload()
    setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
    setModalMode(undefined)
  }
  const handleSubmit = () => {
    let errorMessages0 = [];
    [
      'price',
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      } else if (isNaN(formData[k])) {
        errorMessages0.push({
          field: k,
          message: 'Input correct value'
        })
      } else if (formData[k] <= 0) {
        errorMessages0.push({
          field: k,
          message: 'Input correct value'
        })
      }
    })
    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    setIsUpdating(true);
    const body = { ...formData };
    callPutApiWithAuth(
      `akashx/tap/${props.data.id}/update_price`, body,
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    getStatData()
  }, [])

  useEffect(() => {
    if (props.data.expire_at) {
      calcExpireCount();
      expireInterval = setInterval(() => {
        calcExpireCount();
      }, [1000]);
    }

    return () => {
      if (expireInterval) {
        clearInterval(expireInterval);
      }
    };
  }, [props.data]);

  useEffect(() => {
    if (props.data) {
      if (formData.command == 'modify_stop_loss') {
        setFormData({
          ...formData,
          price: props.data.stop_loss,
        })
      } else if (formData.command == 'modify_take_profit') {
        setFormData({
          ...formData,
          price: props.data.tp1,
        })
      }
    }
  }, [props.data, formData.command])

  return (
    <>
      <Button onClick={() => setModalMode('input')}>
        Update
      </Button>
      {modalMode === 'input' &&
        <Modal width={460}
          onCancel={() => setModalMode(undefined)}
          closeIcon={(
            <div style={{
              color: theme === 'dark' ? '#EFEDF9' : '#585858',
              fontSize: 32,
              position: 'absolute',
              right: '-6px', top: '-16px'
            }}>
              <AiOutlineCloseCircle />
            </div>
          )}
        >
          <ApprWrap>
            <div className='pa-wrap'>
              {(props.data.pair && props.data.pair.image) &&
                <div className='pair-img'>
                  <img src={props.data.pair.image} />
                </div>
              }
              {(props.data.pair) &&
                <div className='pair-name'>
                  <span>{props.data.pair.name} </span>
                </div>
              }
              <div
                className={`type-span 
                  ${varKey('akaTradeTap.orderType', props.data.order_type)}
                `}
              >
                {varLabel('akaTradeTap.orderType', props.data.order_type)}
              </div>
            </div>
            <div className='bal-wrap'>
              <h4>Account Balance:&nbsp;{asCurrencyPrice(statData.balance, statData.currency)}</h4>
            </div>
            <div className='bt-wrap'>
              {props.data.expire_at &&
                <div className={`exp-wrap ${expireTse < 60 ? 'inactive' : ''}`}>
                  <h4>Trade Expiration</h4>
                  <div className='exp-inwrap'>
                    <div className='exphr-wrap'>
                      <label>
                        {expireHr}
                      </label>
                      <span>hr</span>
                    </div>
                    <label>:</label>
                    <div className='exphr-wrap'>
                      <label>
                        {expireMi}
                      </label>
                      <span>min</span>
                    </div>
                    <label>:</label>
                    <div className='exphr-wrap'>
                      <label>
                        {expireSe}
                      </label>
                      <span>sec</span>
                    </div>
                  </div>
                </div>}
              <div className='inp-wrap'>
                <div className='radio-group' >
                  <Radio.Group value={formData.command}
                    onChange={e =>
                      setFormData({ ...formData, command: e.target.value })
                    }
                  >
                    <Radio value={'modify_stop_loss'}>Stop Loss</Radio>
                    <Radio value={'modify_take_profit'}>Take Profit</Radio>
                  </Radio.Group>
                </div>
                <div className='form-group'>
                  <Input value={formData.price}
                    type={'number'}
                    onChange={e =>
                      setFormData({ ...formData, price: e.target.value })
                    }
                    step={0.1}
                  />
                  {errorMessages.filter(el =>
                    el.field == 'price'
                  ).map(el =>
                    <div className='err-wrap'>
                      {el.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='act-wrap'>
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>
                  Update
                </Button>
              </Popconfirm>
            </div>
          </ApprWrap>
        </Modal>
      }
    </>
  )
}

export const Wrapper = styled.div`
  .top-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    flex-wrap: wrap;
  }
  .title-wrap {
    color: #DAA35A;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
  .search-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    // select {
    //   background: rgba(13,13,13,0.20);
    //   border: 1px solid #EFEDF91A;
    //   border-radius: 8px;
    //   color: #EFEDF9;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 400;
    //   margin: 0 8px;
    //   padding: 8px 12px;
    // }
    button {
      border-radius: 8px;
      background: rgba(193, 149, 74, 0.10);
      color: #C1954A;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      border: 0;
      padding: 12px 34px;
      margin-left: 8px;
    }
    @media (max-width: 768px) {
      justify-content: flex-start;
      select {
        margin-top: 4px; 
        margin-bottom: 4px;
        width: 100%;
      }
      button {
        margin-top: 4px; 
        margin-bottom: 4px;
        width: 100%;
      }
    }
  }
  .table-wrap {
    position: relative;
    min-height: 480px;
  }
  .spin-wrap {
    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
  }
  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination-wrap {
    margin: 12px 0;
    display: flex;
    justify-content: flex-end;
    .ant-pagination-prev .ant-pagination-item-link, 
    .ant-pagination-next .ant-pagination-item-link {
      background: transparent;
      border-color: transparent;
      color: #EFEDF9;
    }
    .ant-pagination-disabled .ant-pagination-item-link, 
    .ant-pagination-disabled:hover .ant-pagination-item-link, 
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
      color: rgba(255, 255, 255, 0.5);
    }
    .ant-pagination-item {
      background: transparent;
      border-color: transparent;
      color: #EFEDF9c0;
    }
    .ant-pagination-item-active {
      color: #DAA35A;
    }
  }
  .card-wrap {
    border-radius: 20px;
    border: 1px solid ${props => props.theme.palette.cardBorder};
    background: ${props => props.theme.palette.cardBg};
    padding: 20px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
    .stat-wrap {
      position: absolute;
      top: -12px;
      left: 20px;
      color: #EFEDF9;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 8px;
      padding: 4px 8px;
      background-color: #52C41A;
      &.inactive {
        background-color: #E92626;
      }
      &.pending {
        background-color: #0073E6;
      }
    }
    .ma-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
    .mal-wrap {
      width: calc(100% - 180px);
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .mar-wrap {
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .edu-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100px;
      margin-right: 12px;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
      p {
        margin: 8px 0;
        word-break: break-all;
        text-align: center;
        line-height: 1.2em;
        color: ${props => props.theme.palette.text};
      }
      @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 12px;
        img {
          margin-right: 12px;
        }
      }
    }
    .pa-wrap {
      width: 160px;
      &.type-wrap {
        width: 120px;
      }
      .lbl {
        color: ${props => props.theme.palette.secondaryText};
        margin-bottom: 8px;
      }
      .span {
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        margin: 0;
        height: 50px;
        display: flex;
        align-items: center;
        color: ${props => props.theme.palette.text};
        &.buy {
          color: #52C41A;
        }
        &.sell {
          color: #E92626;
        }
      }
      .exp-wrap {
        border: 1px solid #52C41A;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 52px;
        .exphr-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 8px;
        }
        label {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          line-height: 1.2em;
          color: #52C41A;
        }
        span {
          font-size: 14px;
          text-align: center;
          line-height: 1.1em;
          color: ${props => props.theme.palette.secondaryText};
        }
        &.inactive {
          border-color: #E92626;
          label {
            color: #E92626;
          }
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &.type-wrap {
          width: 100%;
        }
        &.exp-pa-wrap {
          .lbl {
            width: 180px;
          }
        }
        .lbl {
          margin: 0 12px 0 0;
          width: 80px;
        }
        .span {
          height: inherit;
          width: 200px;
        }
        .exp-wrap {
          width: 100%;
          margin: 4px 0 16px 0;
        }
      }
    }
    .tact-wrap {
      height: 45px;
      button {
        border-radius: 8px !important;
        background: rgba(193, 149, 74, 0.10);
        border: 1px solid rgba(193, 149, 74, 0.10);
        color: #C1954A;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
        text-transform: uppercase;
        width: 130px;
        height: 36px;
      }
    }
    .bact-wrap {
      height: 40px;
      button {
        color: #B8B8B8;
        border: 0 none;
        background: transparent;
      }
    }
    .mo-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .ty-wrap {
        margin-right: 24px;
        label {
          color: ${props => props.theme.palette.secondaryText};
          margin-right: 12px;
        }
      }
      .note-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        label {
          color: #DAA35A;
          font-weight: 600;
          margin-right: 8px;
        }
        p {
          color: ${props => props.theme.palette.text};
          text-align: justify;
          font-style: normal;
          font-weight: 400;
          opacity: 0.5;
          margin-right: 8px;
          margin-bottom: 0;
        }
      }
      .note-img {
        @media (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 60px;
          height: 30px;
          object-fit: cover;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 12px;
        }
      }
    }
  }
`
export const ApprWrap = styled.div`
  margin: -24px;
  background: ${props => props.theme.palette.modalBg};
  border: 1px solid ${props => props.theme.palette.modalBorder};
  padding: 20px;
  .pa-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .pair-img {
      margin-right: 18px;
      img {
        width: 70px;
        height: 35px;
        object-fit: cover;
      }
    }
    .pair-name {
      margin-right: 18px;
      color: ${props => props.theme.palette.text};
      font-size: 14px;
      font-weight: 600;
    }
    .type-span {
      border-radius: 50px;
      background: #8d8d8d;
      border: 0;
      color: #ededf9;
      font-size: 12px;
      font-weight: 600;
      padding: 5px 13px;
      margin: 0 0 0 10px;
      &.buy {
        background-color: #52C41A;
      }
      &.sell {
        background-color: #E92626;
      }
      &.marketing {
        background-color: #0073E6;
      }
    }
  }
  .bal-wrap {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1em;
      text-align: center;
      color: ${props => props.theme.palette.text};
    }
  }
  .ep-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: ${props => props.theme.palette.epSpanBg};
    border-radius: 6px;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: flex-start;
      margin-top: 8px;
    }
    .ep-span {
      margin: 0 12px;
      padding: 4px 14px;
      color: ${props => props.theme.palette.text};
      label {
        margin-right: 4px;
        color: ${props => props.theme.palette.secondaryText};
        display: block;
        text-align: center;
        margin: 8px 0;
      }
      span {
        display: block;
        text-align: center;
        font-size: 16px;
        margin: 8px 0;
      }
      @media (max-width: 768px) {
        margin-bottom: 8px;
        margin-left: 0;
      }
    }
  }
  .pair-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 16px;
    div {
      label {
        color: #e2e2e2;
      }
      span {
        color : rgb(170,142,57);
      }
    }
  }
  .bt-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 18px 0 24px 0;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .inp-wrap {
    width: 220px;
    h4 {
      font-size: 16px;
      color: ${props => props.theme.palette.secondaryText};
      text-align: center;
      margin-bottom: 8px;
    }
    .radio-group {
      width: 100%;
      .ant-radio-wrapper {
        color: ${props => props.theme.palette.secondaryText};
        font-size: 16px;
      }
    }
    .form-group {
      width: 100%;
      margin: 4px 8px;
      label {
        color: ${props => props.theme.palette.text};
      }
      input {
        border: 1px solid ${props => props.theme.palette.inputBorder};
        background: ${props => props.theme.palette.inputBg};
        color: ${props => props.theme.palette.text};
        text-align: center;
        min-height: 50px;
        &[disabled] {
          opacity: 0.5;
        }
      }
      .tog-wrap {
        color: #ADADAD;
        font-size: 14px;
        text-align: center;
        margin: 4px 0;
        cursor: pointer;
        &:hover {
          color: #eaeaea;
        }
      }
      .err-wrap {
        color: ${props => props.theme.palette.error};
      }
    }
    @media (max-width: 768px) {
      margin: 4px;
    }
  }
  .exp-wrap {
    color: ${props => props.theme.palette.text};
    h4 {
      font-size: 16px;
      color: #ADADAD;
      text-align: center;
      margin-bottom: 8px;
    }
    .exp-inwrap {
      border: 1px solid #52C41A;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 52px;
      .exphr-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
      }
      label {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 1.2em;
        color: #52C41A;
      }
      span {
        font-size: 14px;
        text-align: center;
        line-height: 1.1em;
        color: #ADADAD;
      }
    }
    &.inactive {
      .exp-inwrap {
        border-color: #E92626;
      }
      label {
        color: #E92626;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
  .act-wrap {
    display: flex;
    justify-content: center;
    button {
      border-radius: 8px !important;
      background: rgba(193, 149, 74, 0.10);
      border: 1px solid rgba(193, 149, 74, 0.10);
      color: #C1954A;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: uppercase;
      width: 130px;
      height: 40px;
      &.approved-btn {
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
`
