import styled from 'styled-components';
import ComingImg from 'assets/coming-soon.png';

export default function ComingSoon() {
  return (
    <Wrapper>
      <p>
        Coming Soon...
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    color: ${props=>props.theme.palette.text};
    font-size: 36px;
    margin-bottom: 0;
  }
`
