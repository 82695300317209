import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spin, DisclaimerBox } from "components/common";
import UpgradeModal from "components/upgrade/UpgradeModal";

export default function FinancialNewsPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    //   const innermostIframe = document.getElementById("asd");
    //   const innermostDocument = innermostIframe.contentDocument;
    //   const innermostBody = innermostDocument.body;
    //   const targetDiv = innermostBody.getElementsByTagName("iframe")[0];
    //   const innermostDocument2 = targetDiv.contentDocument;
    //   const innermostBody2 = innermostDocument2.body;

    //   console.log(
    //     "ddddddddddd",
    //     innermostBody,
    //     targetDiv,
    //     innermostDocument2,
    //     innermostBody2
    //   );
    //   targetDivs.forEach((div) => {
    //     div.style.backgroundColor = "red";
    //   });
    //   // if (targetDiv) {
    //   //   targetDiv.style.backgroundColor = "red";
    //   //   // Add more styles as needed
    //   // }
    // }, 4500);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <Spin spinning={true} className={`spinner`} size="large" />
        </div>
      ) : (
        <>
          <div style={{ height: "calc(100vh - 220px)" }}>
            <iframe src="/ext/financial_news.html" width="100%" height="100%" id="asd" />
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: white;
  padding: 20px 0;
  min-height: 500px;
  .upgrade-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    font-size: 16px;
  }
`;
