
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { 
  Row, Col, 
  Tooltip,
} from "components/common";
import { callGetApiWithAuth } from 'utils/api';
import { asPrice } from 'utils/text';
import { ReactComponent as NophotoIcon } from 'assets/nophoto.svg';

export default function StrategyLbdPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => {
    setTableData(data)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`akashx/leaderboard/list_by_strategies`,
      onGetTableData,
      onFailTableData,
    )
  }
  const handleSearch = () => {
    loadTableData()
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        {tableData.map(el => 
          <Col xs={24} sm={6}>
            <EducatorCard data={el} />
          </Col>
        )}
      </Row>
    </Wrapper>
  )
}

const EducatorCard = ({ data }) => {
  return (
    <div className='edu-card'>
      <div className='photo-wrap'>
        <img src={data.educator.image||NophotoIcon} />
      </div>
      <div className='edu-wrap'>
        <p className='name-span'>
          {data.educator.first_name}&nbsp;
          {data.educator.last_name}
        </p>
        <Tooltip placement="topLeft"
          title={
            <EduDescTipWrap>
              <div 
                dangerouslySetInnerHTML={{__html: data.educator.description}} 
              />
            </EduDescTipWrap>
            
          }
        >
          <p className='desc-span'
            dangerouslySetInnerHTML={{
              __html: data.educator.description
            }}
          />
        </Tooltip>
      </div>
      <div className='btm-wrap'>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Win Ratio
          </p>
          <p className='val-span'>
            {data.won_ratio}%
          </p>
        </div>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Total Profit
          </p>
          <p className='val-span'>
            {asPrice(data.profit)}
          </p>
        </div>
        <div className='val-wrap'>
          <p className='val-lbl'>
            Total Growth
          </p>
          <p className='val-span'>
            {data.growth}%
          </p>
        </div>
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  .edu-card {
    padding: 25px 20px;
    background-color: ${props=>props.theme.palette.cardBg};
    border-radius: 10px;
    .photo-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name-span {
      margin-top: 20px;
      color: ${props=>props.theme.palette.text};
      font-size: 18px;
    }
    .desc-span {
      color: ${props=>props.theme.palette.primary};
      margin: 0;
      height: 88px;
      font-size: 14px;
      line-height: 1.5em;
      height: 84px;
      overflow: hidden;
      p {
        margin: 0;
      }
    }
    .btm-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .val-wrap {
      width: 100%;
    }
    .val-lbl {
      color: ${props=>props.theme.palette.secondaryText};
      margin: 12px 0 0 0;
    }
    .val-span {
      color: ${props=>props.theme.palette.text};
      margin: 0;
    }
  }
`

const EduDescTipWrap = styled.div`
  border-radius: 4x;
  border: 1px solid ${props=>props.theme.palette.modalBorder};
  background: ${props=>props.theme.palette.modalBg};
  color: ${props=>props.theme.palette.text};
  width: 320px;
  padding: 20px;
  p {
    margin-bottom: 0;
  }
`
