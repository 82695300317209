import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { 
  Row, Col,
  DisclaimerBox 
} from "components/common";
import { ReactComponent as Academy1Img } from "assets/academy-1.svg";
import { ReactComponent as Academy2Img } from "assets/academy-2.svg";
import { ReactComponent as Academy3Img } from "assets/academy-3.svg";
import { ReactComponent as Academy4Img } from "assets/academy-4.svg";
import { ReactComponent as Academy5Img } from "assets/academy-5.svg";
import { ReactComponent as Academy6Img } from "assets/academy-6.svg";

export default function AcademyPage() {
  const history = useHistory();

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12}>
          <div className="item-card"
            onClick={() => history.push("/academy/intermediate")}
          >
            <div className="icon-wrap">
              <Academy2Img />
            </div>
            <h2>Platform Tutorials</h2>
            <p>
              The platform tutorials academy will walk you through using the popular trading platforms such as MetaTrader 4, MetaTrader 4, and cTrader.
            </p>
            <div style={{ height: 24 }} />
            <a href="#" >
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="item-card" 
            onClick={() => history.push("/academy/beginner")}
          >
            <div className="icon-wrap">
              <Academy1Img />
            </div>
            <h2>Beginner</h2>
            <p>
              The beginners academy is your introduction to the Forex market.
              You’ll learn about the world of trading, the most popular pairs,
              terminology used, and all of the important information you’ll need
              as you embark this new journey.
            </p>
            <div style={{ height: 24 }} />
            <a href="#" >
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="item-card"
            onClick={() => history.push("/academy/advanced")}
          >
            <div className="icon-wrap">
              <Academy3Img />
            </div>
            <h2>Advanced</h2>
            <p>
              The advanced academy is for traders that already have mastered the basics. You’ll learn about fundamental analysis, technical analysis, indicators, and patterns.
            </p>
            <div style={{ height: 24 }} />
            <a href="#">
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="item-card"
            onClick={() => history.push("/academy/expert")}
          >
            <div className="icon-wrap">
              <Academy4Img />
            </div>
            <h2>Expert</h2>
            <p>
              The expert academy is for those who are ready to take their trading game to the next level. Learn about Japanese candlestick patterns, trading strategies, fibonacci trading, and the elliot wave theory.
            </p>
            <div style={{ height: 24 }} />
            <a href="#">
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="item-card"
            onClick={() => history.push("/academy/crypto")}
          >
            <div className="icon-wrap">
              <Academy5Img />
            </div>
            <h2>Cryptocurrencies</h2>
            <p>
            The crypto academy is for those interested in mastering their crypto portfolio. You'll learn about blockchain, staking, mining, storing, and trading crypto.
            </p>
            <div style={{ height: 24 }} />
            <a href="#">
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="item-card"
            onClick={() => history.push("/academy/educator")}
          >
            <div className="icon-wrap">
              <Academy6Img />
            </div>
            <h2>Educator Training Series</h2>
            <p>
            The educator training series is a compilation of training videos from our Akashx educators. Each of them trade different asset classes using various trading strategies. Learn and apply their trading strategies after going through the series.ut the world of trading, the most popular pairs, terminology used, and all of the important information you’ll need as you embark this new journey.
            </p>
            <div style={{ height: 24 }} />
            <a href="#">
              Get Started
            </a>
          </div>
        </Col>
        <Col xs={24}>
          <DisclaimerBox />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .item-card {
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    background: ${props=>props.theme.palette.cardBg};
    border-radius: 20px;
    padding: 35px 30px;
    height: 100%;
    cursor: pointer;
    .icon-wrap {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #daa35a1a;
      border-radius: 50%;
    }
    h2 {
      font-size: 24px;
      line-height: 33px;
      color: ${props=>props.theme.palette.text};
      margin: 10px 0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: ${props=>props.theme.palette.secondaryText};
      margin: 0px;
    }
    a {
      background: ${props=>props.theme.palette.submitBtnBg};
      padding: 10px 35px;
      color: ${props=>props.theme.palette.submitBtnText};
      border-radius: 12px;
      margin-top: 12px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
