import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Row, Col,
  Pagination, VideoModal,
  Select, Select2,
  Spin, NoData,
  DisclaimerBox,
} from "components/common";
import { varIs, varOptions } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import NoImage from "assets/no_image.png";

export default function ResourcePage() {
  const langRef = useRef(null)
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 12,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    lang: "",
    asset_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = ({ data }) => {
    setTableData(data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    });
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    setIsLoading(true);
    callGetApiWithAuth(
      `akashx/resource/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    handleSearch();
  }, [searchParam]);

  return (
    <Wrapper>
      <div className="top-bar">
        <Select2
          value={searchParam.lang}
          onChange={(e) => {
            setSearchParam({ ...searchParam, lang: e.target.value });
          }}
          options={[
            { value: '', label: 'Select Language' },
            ...varOptions("resource.lang")
          ]}
        />
        <Select2
          value={searchParam.asset_type}
          onChange={(e) => {
            setSearchParam({ ...searchParam, asset_type: e.target.value });
          }}
          options={[
            { value: '', label: 'All Asset Type' },
            ...varOptions("resource.assetType")
          ]}
        />
      </div>
      <div className="list-root">
        <Row gutter={[20, 20]}>
          {tableData.map((el) => (
            <Col xs={24} sm={12} lg={12} xl={12} xxl={8}>
              <ItemCard data={el} />
            </Col>
          ))}
        </Row>
        {isLoading && (
          <div className="spin-wrap">
            <Spin spinning={true} />
          </div>
        )}
        {!isLoading && tableData.length == 0 && (
          <div className="empty-wrap">
            <NoData />
          </div>
        )}
      </div>
      <div className="pagination-wrap">
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
      <div style={{ height: 24 }} />
      <DisclaimerBox />
    </Wrapper>
  );
}

const ItemCard = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined);

  const handleDownload = () => {
    window.open(data.file, "_blank");
  };

  return (
    <div className="item-card">
      <div className="img-wrap">
        <img src={data.thumbnail || NoImage} />
      </div>
      <h4>{data.title}</h4>
      <p dangerouslySetInnerHTML={{ __html: data.description }} />
      {varIs("resource.fileType", data.file_type, "video") ? (
        <button className="play-btn" onClick={() => setModalMode("video")}>
          Watch Now
        </button>
      ) : (
        <button className="play-btn" onClick={handleDownload}>
          Download
        </button>
      )}
      {modalMode === "video" && (
        <VideoModal
          onCancel={() => setModalMode(undefined)}
          src={data.file.replace('https://vimeo.com', 'https://player.vimeo.com/video')}
          width={1200}
          isVimeo
        />
      )}
    </div>
  );
};

const Wrapper = styled.div`
  .top-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
  }
  .list-root {
    position: relative;
    min-height: 480px;
  }
  .spin-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
  }
  .pagination-wrap {
    margin: 12px 0;
    display: flex;
    justify-content: flex-end;
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background: transparent;
      border-color: transparent;
      color: ${props=>props.theme.palette.text};
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
      color: ${props=>props.theme.palette.secondaryText};
    }
    .ant-pagination-item {
      background: transparent;
      border-color: transparent;
      color: ${props=>props.theme.palette.text};
    }
    .ant-pagination-item-active {
      color: #daa35a;
    }
  }
  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-card {
    border-radius: 20px;
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    background: ${props=>props.theme.palette.cardBg};
    padding: 20px;
    .img-wrap {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 14px;
      }
    }
    h4 {
      color: ${props=>props.theme.palette.text};
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    p {
      color: ${props=>props.theme.palette.secondaryText};
      font-size: 14px;
      line-height: 1.5em;
      height: 84px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .play-btn {
      border-radius: 8px !important;
      background: ${props=>props.theme.palette.submitBtnBg};
      border: 1px solid ${props=>props.theme.palette.submitBtnBg};
      color: ${props=>props.theme.palette.submitBtnText};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      padding: 12px 34px;
    }
  }
`;
