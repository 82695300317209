import BounceLoader from 'react-spinners/MoonLoader'
import styled from 'styled-components'

export const Spin = (props) => {
  return (
    props.spinning?
    <Wrapper className={`${props.className}`}>
      <BounceLoader className={'r'} size={40} loading={true} {...props.spinProps} />
      {props.description?
        <p className={`description`}>{props.description}</p>
      : ''}
    </Wrapper>
    : ''
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-color: ${props=>props.theme.palette.primary};
  width: inherit;
  margin: 0;
  left: 0 !important;
  top: 0 !important;
  > span{
    display: inherit;
    border-color: ${props=>props.theme.palette.primary};
  }
  > span > span {
    background-color: ${props=>props.theme.palette.primary};
    &:nth-child(2) {
      border-color: #fff;
      background-color: transparent;
    }
  }
  .description {
    margin-bottom: 4px;
    margin-left: 12px;
    font-size: 18px;
  }
`
