import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Modal, Button, Popconfirm 
} from 'components/common';
import { callPostApiWithAuth } from 'utils/api';
import { getToken } from 'utils/auth';

export default function UpgradeModal() {
  const [formData, setFormData] = useState({
    sku: 'AKASHX_1_month',
    pay_cycle: 30,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  
  const onSubmitDone = ({ data }) => {
    window.open(`${process.env.REACT_APP_CORP_URL}/authed-share-cart?token=${getToken()}&h=${data.hash_code}`, '_blank')
    setIsLoading(false)
  }
  const onSubmitFail = () => {
    setIsLoading(false)
  }
  const handleSubmit = () => {
    setIsLoading(true)
    const body = { ...formData }
    callPostApiWithAuth(`akashx/upgrade/share_cart`, body, 
      onSubmitDone, onSubmitFail
    )
  }

  return (
    <Modal width={640}
      visible={isVisible}
      onCancel={()=>setIsVisible(false)}
    >
      <Wrapper>
        <h3>Upgrade</h3>
        <p className='description'>
          Upgrade your Akashx subscription to our Elite package to gain access to all of our trading tools, live trade calls, scanners, and more. Subscribe monthly or save $389 with an annual membership. 
        </p>
        <div className='select-root'>
          <div className={`product-card ${formData.sku==='AKASHX_1_month'?'selected':''}`}
            onClick={()=>setFormData({
              ...formData,
              sku: 'AKASHX_1_month',
              pay_cycle: 30,
            })}
          >
            <div className='product-img'>
              <img src='https://d2511r1bjh2ay3.cloudfront.net/mdc_products/Akashx/V1_21_2888_2.0+Mockups_Akashx+Product+Image_Logo.png' />
            </div>
            <div className='product-rg-div'>
              <p className='product-title'>
                Elite Monthly
              </p>
              <div className='product-price'> 
                <label>
                  Frequency:
                </label><br/>
                <span className='product-price'>
                  Monthly
                </span>
              </div>
              <div className='product-price'> 
                <label>
                  Price:
                </label><br/>
                <span className='product-price'>
                  $199.00
                </span>
              </div>
            </div>
          </div>
          <div className={`product-card ${formData.sku==='AKASHX_02'?'selected':''}`}
            onClick={()=>setFormData({
              ...formData,
              sku: 'AKASHX_02',
              pay_cycle: 1,
            })}
          >
            <div className='product-img'>
              <img src='https://d2511r1bjh2ay3.cloudfront.net/mdc_products/Akashx/V1_21_2888_2.0+Mockups_Akashx+Product+Image_Logo.png' />
            </div>
            <div className='product-rg-div'>
              <p className='product-title'>
                Elite Annual
              </p>
              <div className='product-price'> 
                <label>
                  Frequency:
                </label><br/>
                <span className='product-price'>
                  Annual
                </span>
              </div>
              <div className='product-price'> 
                <label>
                  Price:
                </label><br/>
                <span className='product-price'>
                  $1,999.00
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='action-root'>
          <Popconfirm onConfirm={handleSubmit}>
            <Button loading={isLoading}>
              Upgrade
            </Button>
          </Popconfirm>
        </div>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .description {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .select-root {
    display: flex;
    justify-content: space-between;
  }
  .product-card {
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(44,39,56,0.06);
    border: 1px solid #fff;
    width: 290px;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
    &.selected {
      box-shadow: 0px 4px 20px rgba(0,180,238,0.3);
      border-color: #00b4ee;
    }
  }
  .product-img {
    width: 130px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  .product-title {
    font-size: 14px;
    color: #384971;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .pay-cycle {
    font-size: 14px;
    background-color: #00b4ee;
    color: #fff;
    padding: 0 12px;
  }
  .product-price {
    margin: 8px 0;
    line-height: 1.5em;
    span {
      font-size: 14px;
      color: #00b4ee;
      margin-right: 12px;
    }
    label {
      font-weight: 500;
      font-size: 14px;
      color: #7f8fa3;
      margin-right: 4px;
    }
  }
  .product-tag {
    width: 100px;
    padding: 7px 7px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 4px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid rgb(60 67 86 / 70%);
    color: rgb(60 67 86 / 70%);
    box-sizing: border-box;
    border-radius: 2px;
    &.selected {
      border-color: #00b2ff;
      color: #00b2ff;
    }
  }
  .action-root {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`
