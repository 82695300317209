import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Input, OutlineBtn, PasswordInput,
  ErrorNotification,
  Spin, message,
} from "components/common";
import { callPostApi } from "utils/api";
import { saveStorage } from "utils/auth";
import LogoImage from "assets/logo3.png";

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const sudoName = useRef(null);
  const pathName = useRef(null);

  const onLoginSuccess = (res) => {
    saveStorage(res.data.user, res.data.token, sudoName.current)
    dispatch({
      type: 'auth.SET_USER',
      payload: {
        user: res.data.user,
        sudoName: sudoName.current
      }
    })
    setIsLoading(false)
    setTimeout(() => {
      history.push(pathName.current ? pathName.current : "/")
    }, 1500);
  };
  const onLoginFailure = (errorMsg) => {
    setIsLoading(false);
    ErrorNotification(errorMsg);
  };

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    // Check validation
    if (!formData.username || !formData.password) {
      message.error("Please input username and password");
      return;
    }

    setIsLoading(true);
    callPostApi(
      "auth/akashx_login",
      {
        username: formData.username.toLowerCase().trim(),
        password: formData.password,
      },
      onLoginSuccess,
      onLoginFailure
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    pathName.current = params.get('pathName');
    if (params.has("auto-token-login")) {
      // Auto-Token Login
      const token = params.get("token");
      sudoName.current = params.get('sudoName');
      setIsLoading(true);
      callPostApi(
        "auth/login/customer_token",
        { token },
        onLoginSuccess,
        onLoginFailure
      );
    }
    if (params.has("autologin")) {
      // Auto Login by Encrypted Username Password pair
      // It comes from Corp Checkout Result page
      const username = params.get("autologin");
      const password = atob(params.get("token"));
      const body = {
        username: username.toLowerCase().trim(),
        password: password,
      };
      setIsLoading(true);
      callPostApi("auth/akashx_login", body, 
        onLoginSuccess, onLoginFailure
      );
    }
  }, []);

  return (
    <Wrapper>
      <div className="form-root">
        <div className="logo-wrapper">
          <img src={LogoImage} />
        </div>
        <div className="please-text">
          Please enter your Akashx username and password to login to your account.
        </div>
        <div className="form-group">
          <div className="field-label">
            <div className="label-text">Email or Username</div>
          </div>
          <Input
            value={formData.username}
            onChange={(e) => onFormInput("username", e.target.value)}
            placeholder={"Enter your username"}
            className="username"
          />
        </div>
        <div className="form-group">
          <div className="field-label">
            <div className="label-text">Password</div>
          </div>
          <PasswordInput
            value={formData.password}
            onChange={(e) => onFormInput("password", e.target.value)}
            onPressEnter={handleSubmit}
            placeholder={"Enter your password"}
            className="password"
          />
        </div>
        <div className="action-group">
          <OutlineBtn
            className={"submit-btn"}
            loading={isLoading}
            onClick={handleSubmit}
          >
            Login
          </OutlineBtn>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 2rem !important;
  width: calc(100% - 100px);
  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    img {
      width: 100%;
      max-width: 300px;
      height: 75px;
    }
  }
  .form-group {
    margin: 24px 0;
    .ant-input-affix-wrapper {
      border-color: #d8d6de;
      border-radius: 0.357rem;
    }
    .ant-input {
      border-color: #d8d6de;
      border-radius: 0.357rem;
    }
  }
  .username {
    height: 40px;
  }
  .password {
    height: 40px;
  }
  .submit-btn {
    width: 100%;
    height: 38px;
  }
  .welcome-text {
    font-size: 28px;
  }
  .please-text {
    color: #6e6b7b;
    margin-top: 7px;
    font-size: 14px;
  }
  .field-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    .label-text {
      font-size: 0.857rem;
      color: #6e6b7bc2;
    }
    .forgot-text {
      font-size: 0.857rem;
      color: #e1a814;
      cursor: pointer;
    }
  }
  .action-group {
    button {
      border-radius: 0.357rem !important;
      background: #e1a814 !important;
      color: white !important;
      border-color: #e1a814 !important;
    }
  }
  @media (max-width: 1360px) {
    padding: 0px !important;
    width: calc(100% - 80px);
    .welcome-text {
      font-size: 18px;
    }
    .please-text {
      font-size: 13px;
    }
  }
  @media (max-width: 1360px) {
    width: 60%;
  }

  @media (max-width: 600px) {
    width: 80%;
  }
`;
