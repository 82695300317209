import React from "react";
import { TinyArea } from "@ant-design/plots";
import styled from "styled-components";

export default function TinyAreaChart({ data }) {
  const config = {
    height: 50,
    width: 150,
    autoFit: false,
    data,
    smooth: true,
    line: {
      color: '#44c176'
    },
    areaStyle: {
      fill: 'l(90) 0:#44c176 1:#e9f8ef'
    },
  };
  return (
    <Wrapper>
      <TinyArea {...config} />
    </Wrapper>
  );
}
export const Wrapper = styled.div``;
