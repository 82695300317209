import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spin, Select } from 'components/common'
import { uiVarOptions } from "common/uiVar";

export default function AdvancedPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState("en");

  const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";

    function getWindowDimensions() {
      const height = hasWindow ? window.innerHeight : null;
      const width = hasWindow ? window.innerWidth : null;
      return {
        height,
        width,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <Wrapper>
      <div className='lang-wrap'>
        <Select 
          value={language}
          options={uiVarOptions("akashx.languages")}
          onChange={(v) => setLanguage(v)}
        />
      </div>
      <div style={{ height: `${height-230}px` }}>
        <iframe src={`/ext/crypto.html?lang=${language}`}
          width="100%" height="100%" 
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
  background: ${props=>props.theme.palette.cardBg};
  border-radius: 20px;
  padding: 20px;
  .spin-root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .lang-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 12px 0;
    .ant-select {
      width: 200px;
    }
  }
`;
