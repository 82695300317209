import React, { useState, useEffect } from 'react';
import {
  Modal, Pagination,
  Spin, NoData, Rate, Select2, Tooltip,
  DisclaimerBox,
} from 'components/common';
import { varOptions, varIsIn, varLabel } from 'common/var';
import { callGetApiWithAuth } from 'utils/api';
import { CloseOutlined } from '@ant-design/icons';
import { Wrapper } from './AlertPage.styled';

export default function ForexAlertPage() {
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5, 
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({
    pair_id: '',
    type_id: '',
    status: '',
    order_type: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [pairs, setPairs] = useState([])
  const [types, setTypes] = useState([])

  const onSearchInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    })
  }
  const onGetPairs = ({ data }) => {
    setPairs(data.pairs);
    setTypes(data.types);
  }
  const onGetTableData = ({ data }) => {
    setTableData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&');
    setIsLoading(true)
    callGetApiWithAuth(`akashx/alert/forex/list?${q}`,
      onGetTableData, onFailTableData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    handleSearch()
    callGetApiWithAuth(`akashx/alert/forex/filters`, 
      onGetPairs
    )
  }, [])

  return (
    <Wrapper>
      <div className='top-root'>
        <div className='title-wrap'>
          Forex
        </div>
        <div className='search-root'>
          <Select2 value={searchParam.pair_id}
            onChange={e=>onSearchInput('pair_id', e.target.value)}
            options={[
              { label: 'Select Pair', value: '' },
              ...pairs.map(el => ({
                value: el.id, label: el.name
              }))
            ]}
            style={{ minWidth: 200 }}
          />
          <Select2 value={searchParam.type_id}
            onChange={e=>onSearchInput('type_id', e.target.value)}
            options={[
              { label: 'Select Type', value: '' },
              ...types.map(el => ({
                value: el.id, label: el.name
              }))
            ]}
            style={{ minWidth: 200 }}
          />
          <Select2 value={searchParam.order_type}
            onChange={e=>onSearchInput('order_type', e.target.value)}
            options={[
              { label: 'Select Order', value: '' },
              ...varOptions('akaForexAlert.orderType')
              .filter(el =>
                el.value!==7 && 
                el.value!==8 && 
                el.value!==5
              )
            ]}
            style={{ minWidth: 200 }}
          />
          <button className='btn-search'
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

      </div>
      
      <div className='table-wrap'>
        {tableData.map(el => (
          <ItemCard data={el} />
        ))}
        {isLoading && 
        <div className='spin-wrap'>
          <Spin spinning={true} />
        </div>}
        {(!isLoading && tableData.length==0) && 
        <div className='empty-wrap'>
          <NoData />
        </div>
        }
      </div>
      <div className='pagination-wrap'>
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
      <div style={{ height: 24 }} />
      <DisclaimerBox />
    </Wrapper>
  )
}

export const ItemCard = ({ data }) => {
  const [cpName, setCpName] = useState(undefined) // CopyPoint Name
  const [modalMode, setModalMode] = useState(undefined)

  const handleCopy = (cpName0) => {
    let text = ''
    if (cpName0 === 'ep') {
      text = `${data.entry_point}`
    } else if (cpName0 === 'sl') {
      text = `${data.stop_loss}`
    } else if (cpName0 === 'tp1') {
      text = `${data.tp1}`
    } else if (cpName0 === 'tp2') {
      text = `${data.tp2}`
    } else if (cpName0 === 'tp3') {
      text = `${data.tp3}`
    } else if (cpName0 === 'tp4') {
      text = `${data.tp4}`
    } else if (cpName0 === 'tp5') {
      text = `${data.tp5}`
    } else if (cpName0 === 'all') {
      text = `Entry Point: ${data.entry_point}, Stop Loss: ${data.stop_loss}, Take Profit1: ${data.tp1}, Take Profit2: ${data.tp2}, Take Profit3: ${data.tp3}, Take Profit4: ${data.tp4}, Take Profit5: ${data.tp5}`
    }
    navigator.clipboard.writeText(text)
    setCpName(cpName0)
    setTimeout(() => {
      setCpName(undefined)
    }, [1500])
  }

  return (
    <div className='card-wrap'>
      <div 
        className={`stat-wrap
          ${varIsIn('akaForexAlert.status', 
            data.status, 
            ['active']
          ) ? 'active' 
          : 'inactive'}
        `}
      >
        {varLabel('akaForexAlert.status', data.status)}
      </div>
      <div className='tp-line'>
        <div className='pa-wrap'>
          {(data.pair && data.pair.image) && 
          <div className='pair-img'>
            <img src={data.pair.image} />
          </div>
          }
          {(data.pair) && 
          <div className='pair-name'>
            <span>{data.pair.name} </span>
          </div>
          }
          <div 
            className={`type-span
              ${varIsIn('akaForexAlert.orderType', 
                data.order_type, 
                ['buy_limit', 'buy_stop', 'buy']
              ) ? 'buy' 
              : varIsIn('akaForexAlert.orderType', 
                data.order_type, 
                ['sell_limit', 'sell_stop', 'sell']
              ) ? 'sell' 
              : 'marketing'}
            `}
          >
            {varLabel('akaForexAlert.orderType', data.order_type)}
          </div>
        </div>
        <div className='ep-wrap'>
          <div className='ep-span'>
            <label>EP:</label>
            <span>{data.entry_point || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>SL:</label>
            <span>{data.stop_loss || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>TP1:</label>
            <span>{data.tp1 || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>TP2:</label>
            <span>{data.tp2 || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>TP3:</label>
            <span>{data.tp3 || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>TP4:</label>
            <span>{data.tp4 || '-'}</span>
          </div>
          <div className='ep-split' />
          <div className='ep-span'>
            <label>TP5:</label>
            <span>{data.tp5 || '-'}</span>
          </div>
        </div>
      </div>
      <div className='bt-line'>
        <div className='lf-wrap'>
          <div className='ty-wrap'>
            <label>
              Strength:&nbsp;
            </label>
            <Rate allowHalf disabled value={data.strength} />
          </div>
          <div className='ty-wrap'>
            <label>
              Created:&nbsp;
            </label>
            <span>
              {data.time_since_listed}
            </span>
          </div>
          {data.type && 
          <div className='ty-wrap'>
            <label>
              Order Type:&nbsp;
            </label>
            <span>
              {data.type.name}
            </span>
          </div>}
          <div className='edu-wrap'>
            {(data.educator && data.educator.image) && 
              <img src={data.educator.image} />
            }
            {data.educator && 
            <span>
              {data.educator.first_name}&nbsp;
              {data.educator.last_name}
            </span>
            }
          </div>
          <div className='note-wrap'>
            <label>Notes:</label>
            {data.chat_image && 
            <div className='note-img'>
              <img src={data.chat_image} 
                onClick={() => setModalMode('chat_image')}
              />
            </div>
            }
            <Tooltip title={()=>(
              <div style={{ 
                width: 450, background: '#0d0d0d',
                padding: '8px 16px',
              }}
                dangerouslySetInnerHTML={{__html: data.note}} 
              />
            )}>
              <p dangerouslySetInnerHTML={{__html: data.note}} />
            </Tooltip>
          </div>
        </div>
        <div className='rg-wrap'>
          <div className='act-wrap'>
            <button onClick={() => handleCopy('all')}>
              Copy All
            </button>
          </div>
        </div>
      </div>
      {modalMode === 'chat_image' && 
      <Modal width={1024}
        closeIcon={(
          <div style={{
            color: '#EFEDF9', 
            width: '40px', height: '40px',
            borderRadius: '50%',
            display: 'flex', alignItems: 'center',
            justifyContent: 'center',
            background: '#ddd',
            position: 'absolute',
            right: '-10px', top: '-10px'
          }}>
            <CloseOutlined />
          </div>
        )}
        onCancel={()=>setModalMode(undefined)}
        bodyStyle={{
          padding: 0
        }}
      >
        <img src={data.chat_image} 
          style={{ width: '100%' }}
          alt={'Chat'}
        />
      </Modal>}
    </div>
  )
}
