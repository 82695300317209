import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { 
  Row, Col, VideoModal, Select2,
  Pagination, Spin, NoData,
} from 'components/common';
import { callGetApiWithAuth } from 'utils/api';
import { AiOutlineArrowLeft } from "react-icons/ai";
import NoPhotoImg from 'assets/nophoto.svg';
import PlayBtnImg from 'assets/play.svg'

export default function EducatorPage() {
  const [viewMode, setViewMode] = useState('list')
  const [selected, setSelected] = useState(undefined)

  return (
    <Wrapper>
      {viewMode == 'list' && 
        <ListView setSelected={setSelected} 
          setViewMode={setViewMode}
        />
      }
      {(viewMode == 'detail' && selected) && 
        <DetailView selected={selected}
          setViewMode={setViewMode}
        />
      }
    </Wrapper>
  )
}

const ListView = ({ setSelected, setViewMode, ...props }) => {
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 120,
    total: 0
  })

  const onGetListData = (res) => {
    setListData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    })
    setIsLoading(false)
  }
  const onFailListData = () => {
    setIsLoading(false)
  }
  const loadListData = (paginationParam0) => {
    setIsLoading(true)
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`akashx/academy/educators?${q}`, 
      onGetListData, onFailListData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadListData(paginationParam0)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadListData(paginationParam0)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <>
      <div className='list-root'>
        {listData.length===0 && 
          <div className='nodata-root'>
            There are no data now.
          </div>
        }
        {isLoading && 
          <div className='loading-root'>
            <Spin spinning={true} />
          </div>
        }
        <Row gutter={[15, 15]} className='list-wrapper'>
          {listData.map(el => (
            <Col xs={24} sm={12} md={8} lg={6}>
              <EducatorCard data={el} 
                setViewMode={setViewMode}
                setSelected={setSelected}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="pagination-root">
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
    </>
  )
}

const EducatorCard = ({ 
  data, setViewMode, setSelected 
}) => {
  const onOpenEducator = () => {
    setViewMode('detail')
    setSelected(data)
  }

  return (
    <div className='educator-card' onClick={onOpenEducator}>
      <div className='profile-root'>
        <div className="image-container">
          <img src={data.image || NoPhotoImg} alt="" />
        </div>
        <div className="user-details">
          <div className="user-name">
            <span className="span-1">
              {`${data.first_name} ${data.last_name}`}
            </span>
          </div>
          <div className="user-sub-title"
            dangerouslySetInnerHTML={{
              __html: data.short_description
            }}
          />
          <div className="user-description"
            dangerouslySetInnerHTML={{
              __html: data.description
            }}
          />
          {data.youtube_link && 
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src={data.youtube_link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          }
          {data.top_interests.length>0?
          <div className="top-container">
            <div className="top-rated-text">Top Interests</div>
            <div className="top-rated-badges">
              {data.top_interests.map(el => (
                <span className="badge-0 badge-1">
                  {el.icon}&nbsp;
                  {el.title}
                </span>
              ))}
            </div>
          </div>
          : ''}
          <div className="spec-container">
            <div className="spec-name">Specialties</div>
            <div className="spec-desc">
              {data.speciality}
            </div>
          </div>
          <div className="spec-container spc-boc">
            <div className="spec-name">Language</div>
            <div className="spec-desc">
              {data.languages.map(el=>el.title).join(', ')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DetailView = ({ setViewMode, selected }) => {
  const [listData, setListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0
  })
  const [searchParam, setSearchParam] = useState({
    category_id: '',
    language_id: '',
  })
  const [categories, setCategories] = useState([])
  const [languages, setLanguages] = useState([])

  const onGetListData = (res) => {
    setListData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    })
    setIsLoading(false)
  }
  const onFailListData = () => {
    setIsLoading(false)
  }
  const loadListData = (paginationParam0, searchParam0) => {
    setIsLoading(true)
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`akashx/academy/${selected.id}/courses?${q}`, 
      onGetListData, onFailListData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadListData(paginationParam0)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadListData(paginationParam0)
  }

  const onGetFilters = (res) => {
    setCategories(res.data.categories)
    setLanguages(res.data.languages)
  }
  const getFilters = () => {
    callGetApiWithAuth(`akashx/academy/filters`, onGetFilters)
  }

  const onSearchInput = (field, value) => {
    let searchParam0 = {...searchParam}
    searchParam0[field] = value
    setSearchParam(searchParam0)
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadListData(paginationParam0, searchParam0)
  }

  useEffect(() => {
    handleSearch()
    getFilters()
  }, [])

  return (
    <Wrapper>
      <div className='top-action-bar'>
        <a className="go-home" onClick={() => setViewMode("list")}>
          <AiOutlineArrowLeft /> back
        </a>
        <div className='d-flex align-items-center'>
          <Select2
            value={searchParam.language_id}
            onChange={e=>onSearchInput('language_id', e.target.value)}
            options={[
              { label: 'Select Language', value: '' },
              ...languages.map(el => ({
                label: `${el.title}`,
                value: el.id
              }))
            ]}
          />
        </div>
      </div>
      <div className='educator-root'>
        <div className='profile-root'>
          <div className="image-container">
            <img src={selected.image || NoPhotoImg} alt="" />
          </div>
          <div className="user-details">
            <div className="user-name">
              <span className="span-1">
                {`${selected.first_name} ${selected.last_name}`}
              </span>
            </div>
            <div className="user-sub-title"
              dangerouslySetInnerHTML={{
                __html: selected.short_description
              }}
            />
            <div className="user-description"
              dangerouslySetInnerHTML={{
                __html: selected.description
              }}
            />
            {selected.youtube_link && 
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src={selected.youtube_link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            }
            {selected.top_interests.length>0?
            <div className="top-container">
              <div className="top-rated-text">Top Interests</div>
              <div className="top-rated-badges">
                {selected.top_interests.map(el => (
                  <span className="badge-0 badge-1">
                    {el.icon}&nbsp;
                    {el.title}
                  </span>
                ))}
              </div>
            </div>
            : ''}
            <div className="spec-container">
              <div className="spec-name">Specialties</div>
              <div className="spec-desc">
                {selected.speciality}
              </div>
            </div>
            <div className="spec-container spc-boc">
              <div className="spec-name">Language</div>
              <div className="spec-desc">
                {selected.languages.map(el=>el.title).join(', ')}
              </div>
            </div>
          </div>
        </div>
        <div className='course-list'>
          {listData.length===0 && 
            <div className='nodata-root'>
              <NoData />
            </div>
          }
          {isLoading && 
            <div className='loading-root'>
              <Spin spinning={true} />
            </div>
          }
          <Row gutter={[15, 15]} className='list-wrapper'>
            {listData.map(el => (
              <Col xs={24} lg={8}>
                <CourseCard data={el} />
              </Col>
            ))}
          </Row>
          <div className="pagination-root">
            <Pagination
              current={paginationParam.currentPage}
              pageSize={paginationParam.perPage}
              total={paginationParam.total}
              showSizeChanger={false}
              onChange={onPageChange}
              hideOnSinglePage={true}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const CourseCard = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined)

  return (
    <div className='course-card'>
      <div className='video-wrapper'>
        <img src={data.image} className='thumbnail' 
          alt={'Vimeo Video'}
        />
        <img src={PlayBtnImg} className='play-btn'
          alt={'Play Video'}
          onClick={() => setModalMode('video')}
        />
        {modalMode === 'video' && 
          <VideoModal
            onCancel={() => setModalMode(undefined)}
            src={data.vimeo_url.replace('https://vimeo.com', 'https://player.vimeo.com/video')}
            width={1200}
            isVimeo
          />
        }
      </div>
      <div className='desc-wrapper'>
        <div className='title'>
          {data.title}
        </div>
        <div className='desc'
          dangerouslySetInnerHTML={{
            __html: data.description
          }}
        />
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  .top-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
    .go-home {
      padding: 0;
      display: flex;
      align-items: center;
      color: ${props=>props.theme.palette.secondaryText};
      font-size: 12px;
      background: #C1954A1A;
      color: #C1954A;
      padding: 6px 12px;
      border-radius: 8px;
      line-height: 1.2em;
      svg {
        margin-right: 8px;
      }
    }
  }
  .list-root {
    position: relative;
  }
  .nodata-root {
    display: flex;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
  .loading-root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: ${props=>props.theme.palette.cardBg};
  }
  .pagination-root {
    margin-top: 24px;
  }
  .educator-card {
    height: 100%;
    cursor: pointer;
    border-radius: 6px;
    background: ${props=>props.theme.palette.cardBg};
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    color: ${props=>props.theme.palette.text};
    padding: 20px;
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user-details {
      .user-name {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .span-1 {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.text};
          margin-right: 13px;
        }
        .span-2 {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #f03f3f;
          img {
            margin-left: 5px;
          }
        }
      }
      .user-sub-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #C1954A;
        margin: 6px 0px 5px;
      }
      .user-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${props=>props.theme.palette.text};
      }
      .video-container {
        iframe {
          width: 100%;
          height: 175px;
          object-fit: contain;
          margin: 20px 0px;
        }
      }
      .top-container {
        margin-top: 15px;
        .top-rated-text {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.secondaryText};
        }
        .top-rated-badges {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 13px;
          .badge-0 {
            padding: 3px 15px;
            display: flex;
            align-items: center;
            border-radius: 20px;
            margin-right: 8px;
            margin-bottom: 12px;
            img {
              margin-right: 8px;
              position: relative;
              top: -1px;
            }
          }
          .badge-1 {
            background: rgb(28 170 111 / 20%);
            color: #1caa6f;
          }
          .badge-2 {
            background: rgb(0 178 255 / 20%);
            color: #00b2ff;
          }
          .badge-3 {
            background: rgb(255 115 0 / 20%);
            color: #ff7301;
          }
          .badge-4 {
            background: rgb(141 17 238 / 20%);
            color: #8d11ee;
          }
          .badge-5 {
            background: rgb(230 207 0 / 20%);
            color: #e6cf00;
          }
          .badge-6 {
            background: rgb(235 51 51 / 20%);
            color: #eb3333;
          }
        }
      }
      .spc-boc {
        margin-top: 10px;
      }
      .spec-container {
        .spec-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.secondaryText};
          margin: 5px 0px 3px;
        }
        .spec-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: ${props=>props.theme.palette.text};
        }
      }
    }
  }
  .educator-root {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .user-details {
      .user-name {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .span-1 {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.text};
          margin-right: 13px;
        }
        .span-2 {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #f03f3f;
          img {
            margin-left: 5px;
          }
        }
      }
      .user-sub-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #C1954A;
        margin: 6px 0px 5px;
      }
      .user-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${props=>props.theme.palette.text};
      }
      .video-container {
        iframe {
          width: 100%;
          height: 175px;
          object-fit: contain;
          margin: 20px 0px;
        }
      }
      .top-container {
        margin-top: 15px;
        .top-rated-text {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.secondaryText};
        }
        .top-rated-badges {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 13px;
          .badge-0 {
            padding: 3px 15px;
            display: flex;
            align-items: center;
            border-radius: 20px;
            margin-right: 8px;
            margin-bottom: 12px;
            img {
              margin-right: 8px;
              position: relative;
              top: -1px;
            }
          }
          .badge-1 {
            background: rgb(28 170 111 / 20%);
            color: #1caa6f;
          }
          .badge-2 {
            background: rgb(0 178 255 / 20%);
            color: #00b2ff;
          }
          .badge-3 {
            background: rgb(255 115 0 / 20%);
            color: #ff7301;
          }
          .badge-4 {
            background: rgb(141 17 238 / 20%);
            color: #8d11ee;
          }
          .badge-5 {
            background: rgb(230 207 0 / 20%);
            color: #e6cf00;
          }
          .badge-6 {
            background: rgb(235 51 51 / 20%);
            color: #eb3333;
          }
        }
      }
      .spc-boc {
        margin-top: 10px;
      }
      .spec-container {
        .spec-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: ${props=>props.theme.palette.secondaryText};
          margin: 5px 0px 3px;
        }
        .spec-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: ${props=>props.theme.palette.text};
        }
      }
    }
    .profile-root {
      width: 280px;
      padding-right: 20px;
      border-right: 1px solid ${props=>props.theme.palette.secondaryText};
      @media (max-width: 1024px) {
        width: 100%;
        border-right-width: 0px;
      }
    }
  }
  .course-list {
    width: calc(100% - 300px);
    @media (max-width: 1024px) {
      width: 100%;
      margin-top: 24px;
      border-top: 1px solid ${props=>props.theme.palette.cardBorder};
      padding-top: 24px;
    }
  }
  .course-card {
    height: 100%;
    .video-wrapper {
      position: relative;
      margin-bottom: 0;
      border-style: solid;
      border-color: ${props=>props.theme.palette.cardBorder};
      border-width: 1px 1px 0 1px;
      border-radius: 6px 6px 0 0;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;
        opacity: 0;
        background-color: transparent;
        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
        z-index: 5;
      }
      &:hover::before {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.45);
      }
      &:hover  .play-btn {
        display: block;
      }
    }
    .thumbnail {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
    .play-btn {
      width: 64px;
      height: 64px;
      position: absolute;
      top: calc(50% - 32px);
      left: calc(50% - 32px);
      cursor: pointer;
      display: none;
      z-index: 7;
      @media (max-width: 768px) {
        display: block;
      }
    }
    .desc-wrapper {
      display: flex;
      // justify-content: center;
      // text-align: center;
      background: ${props=>props.theme.palette.cardBg};
      border: 1px solid ${props=>props.theme.palette.cardBorder};
      border-radius: 0 0 6px 6px;
      padding: 8px;
      // min-height: 80px;
      color: ${props=>props.theme.palette.secondaryText};
      flex-direction: column;
      height: calc(100% - 320px);
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 12px;
        color: ${props=>props.theme.palette.text};
        padding: 8px;
        font-weight: bold;
      }
    }
  }
`
