import React from "react";
import { useSelector } from "react-redux";
import Routes from "./Routes";
import "antd/dist/antd.css";
import "./App.css";
import { theme, liTheme } from "common/theme";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const themeName = useSelector(state=>state.ui.theme)

  return (
    <ThemeProvider theme={themeName==='dark'?theme:liTheme}>
      <div
        id="myapp"
        style={{ height: "100%", overflow: "auto", background: "#0D0D0D" }}
      >
        <Wrapper className='app-inner'>
          <Routes />
        </Wrapper>
      </div>
    </ThemeProvider>
  );
}

export default App;

const Wrapper = styled.div`
  background: #f8f8f8;
  height: 100%;
`;