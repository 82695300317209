import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TablePanel,
  Tooltip,
  DisclaimerBox,
  SuccessNotification,
} from "components/common";
import { callGetApiWithAuth, callPutApiWithAuth } from "utils/api";
import { asDateTime } from "utils/text";
import { BsSquare, BsCheck2Square } from "react-icons/bs";
import NoPhotoImg from "assets/nophoto.svg";

export default function HomePage() {
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 12,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);

  const onGetTableData = ({ data }) => {
    setTableData(data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    });
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0) => {
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    setIsLoading(true);
    callGetApiWithAuth(
      `akashx/news/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0);
  };
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0);
  };

  const onSubmitDone = (res) => {
    SuccessNotification(res.message);
    handleSearch();
  };
  const onSubmitFail = () => {};

  const onCheck = (id) => {
    let isExist = false;
    checkedIds.forEach((el) => {
      if (el * 1 == id * 1) {
        isExist = true;
      }
    });
    let checkedIds0 = [];
    if (isExist) {
      checkedIds0 = checkedIds.filter((el) => el * 1 !== id * 1);
    } else {
      checkedIds0 = [...checkedIds];
      checkedIds0.push(id);
    }
    setCheckedIds(checkedIds0);
  };
  const handleRead = () => {
    if (checkedIds.length===0) return;

    const body = {
      news_ids: checkedIds,
    };
    callPutApiWithAuth(
      `akashx/news/set_bulk_read`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };
  const handleUnread = () => {
    if (checkedIds.length===0) return;

    const body = {
      news_ids: checkedIds,
    };
    callPutApiWithAuth(
      `akashx/news/unset_bulk_read`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Wrapper>
      <div className="top-bar">
        <button onClick={handleRead}>Mark as Read</button>
        <button onClick={handleUnread}>Mark as Unread</button>
      </div>
      <div className="list-root">
        <TablePanel
          data={tableData}
          loading={isLoading}
          paginationParam={paginationParam}
          onPageChange={onPageChange}
          columns={[
            {
              title: "",
              key: "checkbox",
              render: (_, record) => (
                <span className="chk-td" onClick={() => onCheck(record.id)}>
                  {checkedIds.filter((el) => el * 1 == record.id * 1).length >
                  0 ? (
                    <BsCheck2Square />
                  ) : (
                    <BsSquare />
                  )}
                </span>
              ),
            },
            {
              title: "Subject",
              key: "title",
              render: (_, record) => (
                <p className={`title-td ${record.is_readed ? "readed" : ""}`}>
                  {record.title}
                </p>
              ),
            },
            {
              title: "Description",
              key: "description",
              render: (_, record) => (
                <Tooltip
                  title={<TooltipContent data={record} />}
                  placement="bottomLeft"
                >
                  <p
                    className={`desc-td ${record.is_readed ? "readed" : ""}`}
                    dangerouslySetInnerHTML={{
                      __html: record.description,
                    }}
                  />
                </Tooltip>
              ),
            },
            {
              title: "Date",
              key: "created_at",
              render: (_, record) => (
                <p className={`date-td ${record.is_readed ? "readed" : ""}`}>
                  {asDateTime(record.created_at)}
                </p>
              ),
            },
          ]}
        />
      </div>
      <div style={{ height: 24 }} />
      <DisclaimerBox />
    </Wrapper>
  );
}

const TooltipContent = ({ data }) => {
  return (
    <TipWrap>
      <div className="noti-item">
        <div className="noti-item-head">
          <div className="noti-item-author">
            &nbsp;
          </div>
          <div className="noti-item-date">{asDateTime(data.created_at)}</div>
        </div>
        <div className="noti-item-body">
          <p className="noti-item-title">{data.title}</p>
          {data.image && <img src={data.image} />}
          <p
            dangerouslySetInnerHTML={{ __html: data.description }}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
    </TipWrap>
  );
};

const Wrapper = styled.div`
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    button {
      border-radius: 8px !important;
      background: rgba(193, 149, 74, 0.1);
      border: 1px solid rgba(193, 149, 74, 0.1);
      color: #c1954a;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      padding: 12px 14px;
      margin-left: 12px;
    }
  }
  .title-td,
  .desc-td,
  .date-td {
    width: 250px;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    p {
      margin: 0;
    }
    &.readed {
      text-decoration: line-through;
    }
  }
  .desc-td {
    width: 550px;
  }
  .chk-td {
    cursor: pointer;
    font-size: 16px;
  }
  .cardRoot {
    color: ${props=>props.theme.palette.text};
    border-radius: 15px;
    background: ${props=>props.theme.palette.cardBg};
  }
  .panelHead {
    display: none;
  }
  .tableWrapper {
    margin-top: -20px;
  }
  .ant-table thead tr th {
    background: #2d2921;
    color: ${props=>props.theme.palette.th};
  }
  .ant-table {
    color: ${props=>props.theme.palette.text};
    background-color: transparent;
  }
  .tableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
    opacity: 0.8;
  }
  .ant-table-thead > tr > th {
    border-color: #efedf91a;
  }
  .ant-table-tbody > tr > td {
    border-color: #efedf91a;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
  }
  .ant-table-placeholder:hover td {
    background: transparent !important;
  }
`;
const TipWrap = styled.div`
  border-radius: 20px;
  border: 1px solid ${props=>props.theme.palette.modalBorder};
  background: ${props=>props.theme.palette.modalBg};
  width: 420px;
  padding: 0 20px;
  .noti-item {
    color: ${props=>props.theme.palette.text};
    padding: 15px 0;
  }
  .noti-item-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .noti-item-author {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      color: ${props=>props.theme.palette.text};
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .noti-item-date {
      color: ${props=>props.theme.palette.text};
      font-size: 12px;
    }
  }
  .noti-item-body {
    p {
      color: ${props=>props.theme.palette.secondaryText};
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
    .noti-item-title {
      color: ${props=>props.theme.palette.text};
      font-size: 14px;
    }
    img {
      max-width: 100%;
      margin: 10px 0 0;
      border-radius: 20px;
    }
  }
`;
