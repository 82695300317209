import React, { useState, useEffect } from 'react';
import {
  Modal, Pagination,
  Spin, NoData, Rate, Select2, Tooltip,
  DisclaimerBox,
} from 'components/common';
import { varOptions, varIsIn, varLabel } from 'common/var';
import { callGetApiWithAuth } from 'utils/api';
import { CloseOutlined } from '@ant-design/icons';
import { Wrapper } from './AlertPage.styled';
import { ItemCard as ForexItemCard } from './ForexAlertPage';
import { ItemCard as BinaryItemCard } from './BinaryOptionAlertPage';
import { ItemCard as CommodityItemCard } from './CommodityAlertPage';
import { ItemCard as CryptoItemCard } from './CryptoAlertPage';
import { ItemCard as IndexItemCard } from './IndexAlertPage';

export default function AllAlertPage() {
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 20,
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({
    pair_id: '',
    type_id: '',
    status: '',
    order_type: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [pairs, setPairs] = useState([])
  const [types, setTypes] = useState([])

  const onSearchInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    })
  }
  const onGetPairs = ({ data }) => {
    setPairs(data.pairs);
    setTypes(data.types);
  }
  const onGetTableData = ({ data }) => {
    setTableData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params).map(k => k + '=' + params[k]).join('&');
    setIsLoading(true)
    callGetApiWithAuth(`akashx/alert/forex/list_v2?${q}`,
      onGetTableData, onFailTableData
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    handleSearch()
    callGetApiWithAuth(`akashx/alert/forex/filters`,
      onGetPairs
    )
  }, [])

  return (
    <Wrapper>
      <div className='top-root'>
        <div className='title-wrap'>
          Trade Alerts
        </div>
        <div className='search-root'>
          <Select2 value={searchParam.pair_id}
            onChange={e => onSearchInput('pair_id', e.target.value)}
            options={[
              { label: 'Select Pair', value: '' },
              ...pairs.map(el => ({
                value: el.id, label: el.name
              }))
            ]}
            style={{ minWidth: 200 }}
          />
          <Select2 value={searchParam.type_id}
            onChange={e => onSearchInput('type_id', e.target.value)}
            options={[
              { label: 'Select Type', value: '' },
              ...types.map(el => ({
                value: el.id, label: el.name
              }))
            ]}
            style={{ minWidth: 200 }}
          />
          <Select2 value={searchParam.order_type}
            onChange={e => onSearchInput('order_type', e.target.value)}
            options={[
              { label: 'Select Order', value: '' },
              ...varOptions('akaForexAlert.orderType')
                .filter(el =>
                  el.value !== 7 &&
                  el.value !== 8 &&
                  el.value !== 5
                )
            ]}
            style={{ minWidth: 200 }}
          />
          <button className='btn-search'
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

      </div>

      <div className='table-wrap'>
        {tableData.map(el => {
          switch (el.alert_type) {
            case 0:
              return (
                <ForexItemCard data={el} />
              )
            case 1:
              return (
                <CryptoItemCard data={el} />
              )
            case 2:
              return (
                <CommodityItemCard data={el} />
              )
            case 3:
              return (
                <BinaryItemCard data={el} />
              )
            case 4:
              return (
                <IndexItemCard data={el} />
              )
          }
        })}
        {isLoading &&
          <div className='spin-wrap'>
            <Spin spinning={true} />
          </div>}
        {(!isLoading && tableData.length == 0) &&
          <div className='empty-wrap'>
            <NoData />
          </div>
        }
      </div>
      <div className='pagination-wrap'>
        <Pagination
          current={paginationParam.currentPage}
          pageSize={paginationParam.perPage}
          total={paginationParam.total}
          showSizeChanger={false}
          onChange={onPageChange}
          hideOnSinglePage={true}
        />
      </div>
      <div style={{ height: 24 }} />
      <DisclaimerBox />
    </Wrapper>
  )
}