import styled from "styled-components";

export default function DisclaimerBox() {
  return (
    <Wrapper>
      <h3>Disclaimer:</h3>
      <p>
        Disclaimer: All content and information provided on this website are for educational and informational purposes only, specifically relating to trading in financial markets. They should not be construed as specific investment recommendations, endorsements, or solicitations to buy or sell securities or any other investment instruments. It’s emphasized that trading in financial markets carries inherent risks, and potential traders are advised not to invest more than they can afford to lose.
      </p>
      <p>
        Akashx does not provide direct broker services, trading services, or hold custody of any investor funds. The Company is also not a licensed investment service provider and does not offer any services that would fall under such categorization. Any references to “trading” or “trader” on our platform should be understood as notional or fictitious trading on demo accounts. This website is operated under the jurisdiction of applicable local laws and regulations. Akashxchange, LLC assumes no responsibility or liability for any misinterpretation, misuse, or reliance on the information provided on this website.
      </p>
      <p>
        Trading Futures, Options on Futures and FOREX involves substantial risk of loss and is not suitable for all investors. Opinions, market data, and recommendations are subject to change without notice. Past performance is not indicative of future results.
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(240, 107, 107, 0.20);
  background: rgba(240, 107, 107, 0.05);
  display: inline-block;
  width: 100%;
  padding: 20px 20px 20px;
  h3 {
    color: #F06B6B;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 13px;
  }
  p {
    color: #C1954A;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 22px;
  }
`
