import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Select2, Spin, DisclaimerBox } from "components/common";
import { uiVarOptions } from "common/uiVar";
import { DoubleLeftOutlined } from "@ant-design/icons";
import CryptoImg from "assets/crypto.png";
import EconomicImg from "assets/economic.png";

export default function CalendarPage(props) {
  const [viewMode, setViewMode] = useState("crypto");
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    setLanguage("en");
  }, [viewMode]);

  return (
    <Wrapper>
      <div className="prop-list">
        <div
          className={`prop-card ${viewMode == "crypto" ? "active" : ""}`}
          onClick={() => setViewMode("crypto")}
        >
          <img src={CryptoImg} />
          <h2>Crypto Calendar</h2>
        </div>
        <div
          className={`prop-card ${viewMode == "economic" ? "active" : ""}`}
          onClick={() => setViewMode("economic")}
        >
          <img src={EconomicImg} />
          <h2>Economic Calendar</h2>
        </div>
      </div>
      <div className="lang-wrap">
        <Select2
          value={language}
          options={uiVarOptions("akashx.languages")}
          onChange={(e) => setLanguage(e.target.value)}
        />
      </div>
      {viewMode === "crypto" && (
        <div className="crypto-conatiner">
          <iframe
            src={`/ext/calendar_crypto.html?lang=${language}`}
            width="100%"
            height="100%"
          />
        </div>
      )}
      {viewMode === "economic" && (
        <div className="economic-conatiner">
          <iframe
            src={`/ext/calendar_eco.html?lang=${language}`}
            width="100%"
            height="100%"
          />
        </div>
      )}
      <div style={{ height: "24px" }} />
      <DisclaimerBox />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  .head-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .prop-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .prop-card {
      width: calc(50% - 24px);
      border: 1px solid ${props=>props.theme.palette.cardBorder};
      background: ${props=>props.theme.palette.cardBg};
      padding: 20px;
      border-radius: 15px;
      cursor: pointer;
      margin-right: 12px;
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 10px;
      }
      h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 33px;
        color: ${props=>props.theme.palette.text};
        margin: 12px 0 0 0;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 12px;
        img {
          height: 120px;
        }
      }
      &.active {
        h2 {
          color: #daa35a;
        }
      }
    }
  }
  .lang-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 12px 0;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-right: 15px;
    .ant-select {
      width: 200px;
    }
  }
  .spin-root {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    .lang-wrap {
      position: unset;
      margin: 0px 0;
      padding-top: 10px;
      padding-bottom: 20px;
      .ant-select {
        width: 100%;
      }
    }
    .select-wrapper {
      width: 100%;
    }
  }

  .crypto-conatiner,
  .economic-conatiner {
    background: #fff;
    border: 1px solid #edeef3;
    box-shadow: 0px 3px 2px 0px #edeef3;
    height: 700px;
    padding: 20px;
    border-radius: 20px;
  }
`;
