import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tooltip, Switch } from "components/common";
import { getPageTitle } from "common/menu";
import { asDateTime } from "utils/text";
import { MenuOutlined } from "@ant-design/icons";
import LangBadge from "./LangBadge";
import { AiOutlineLogout, AiOutlineArrowLeft } from "react-icons/ai";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import NotiImg from "assets/noti.png";
import NotiNoneImg from "assets/noti_none.svg";
import Noti2Img from "assets/noti2.png";
import Noti2NoneImg from "assets/noti2_none.svg";
import NoPhotoImg from "assets/nophoto.svg";

export default function TopHeader({ showDrawer }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCollapse = useSelector((state) => state.ui.isCollapse);
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudoName);
  const theme = useSelector(state => state.ui.theme);

  const handleLogout = ({ key }) => {
    history.push("/logout");
  };
  const onClickAccountMenu = ({ key }) => {
    if (key == "logout") {
      history.push("/logout");
    }
    history.push(key);
  };
  const goNotiPage = () => {
    history.push("/news");
  };

  const handleScroll = (e) => {
    if (
      typeof window !== "undefined" &&
      document.getElementById("localize-widget")
    ) {
      if (sudoName) {
        document.getElementById("localize-widget").style.display = "none";
      } else if (e.target.scrollTop > 30) {
        document.getElementById("localize-widget").style.display = "none";
      } else {
        document.getElementById("localize-widget").style.display = "block";
      }
    }
  };

  const onChangeTheme = (checked) => {
    if (checked) {
      dispatch({
        type: 'ui.SET_THEME',
        payload: {
          theme: 'dark'
        }
      })
    } else {
      dispatch({
        type: 'ui.SET_THEME',
        payload: {
          theme: 'light'
        }
      })
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      document.getElementById("localize-widget")
    ) {
      if (sudoName) {
        document.getElementById("localize-widget").style.display = "none";
      } else {
        document.getElementById("localize-widget").style.display = "block";
      }
    }
  }, [sudoName]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        document.getElementById("localize-widget") &&
        document.getElementsByClassName("avatar-container").length > 0
      ) {
        let localizeSelector = document.getElementById("localize-widget");
        let avatarContainerSelector =
          document.getElementsByClassName("avatar-container")[0];

        localizeSelector.style.right =
          avatarContainerSelector.clientWidth + 70 + "px";
      }
    }
  }, []);

  return (
    <Wrapper>
      <div className="title-root">
        <span className="mobile-side-icon">
          <MenuOutlined onClick={showDrawer} />
        </span>
        {/* (
          history?.location?.pathname !== "/publisher" &&
          history?.location?.pathname !== "/viewer"
        ) &&
        <>
          (isCollapse ? (
            <span className="mobile-collapse-icon">
              <DoubleRightOutlined
                onClick={() => {
                  dispatch({
                    type: "ui.SET_COLLAPSE",
                    payload: {
                      isCollapse: false,
                    },
                  });
                }}
              />
            </span>
          ) : (
            <span className="mobile-collapse-icon">
              <DoubleLeftOutlined
                onClick={() => {
                  dispatch({
                    type: "ui.SET_COLLAPSE",
                    payload: {
                      isCollapse: true,
                    },
                  });
                }}
              />
            </span>
          ))
        </> */}
        <div className="page-title">
          {history.location.pathname.indexOf("/academy/") >= 0 && (
            <span
              className="back-btn"
              onClick={() => {
                history.push("/academy");
              }}
            >
              <AiOutlineArrowLeft />
              &nbsp; back
            </span>
          )}
          {history.location.pathname == "/" ? (
            <>
              <h4 className="desktop-title">
                {/* <p className="subtitle">
                  Live Education from Top Educators all over the world
                </p> */}
                <p className="user-first-name">Hello, {myUser?.first_name}&nbsp;<span style={{ fontSize: '18px' }}>👋</span></p>
              </h4>
              <h4 className="mobile-title">Home</h4>
            </>
          ) : getPageTitle(history.location.pathname, myUser) ? (
            <h4>{getPageTitle(history.location.pathname, myUser)}</h4>
          ) : (
            <h4>
              {history.location.pathname?.split("/")[1]?.replace("_", " ")}
            </h4>
          )}
        </div>
      </div>
      <div className="avatar-root">
        <div className='theme-select'>
          <Switch checkedChildren={<MdDarkMode />} 
            unCheckedChildren={<MdLightMode />}
            checked={theme === 'dark'}
            onChange={onChangeTheme}
          />
        </div>
        <LangBadge />
        {myUser && myUser.latest_news && (
          <div className="noti-wrap">
            <Tooltip title={NotiSection} placement="bottomRight">
              <img
                src={
                  myUser.latest_news.length > 0
                  ? (theme==='dark'?NotiImg:Noti2Img)
                  : (theme==='dark'?NotiNoneImg:Noti2NoneImg)
                }
              />
            </Tooltip>
          </div>
        )}
        {myUser && myUser.latest_news && (
          <div className="noti-wrap noti-mobile-wrap">
            <img
              src={
                myUser.latest_news.length > 0
                ? (theme==='dark'?NotiImg:Noti2Img)
                : (theme==='dark'?NotiNoneImg:Noti2NoneImg)
              }
              onClick={goNotiPage}
            />
          </div>
        )}
        <div className="photo-wrap">
          <img src={myUser?.image || NoPhotoImg} />
        </div>
        <div className="divider" />
        <div className="logout-wrap">
          <button className="logout-btn" onClick={handleLogout}>
            Logout
          </button>
        </div>
        <div className={`logout-mobile-wrap theme-${theme}`}
          onClick={handleLogout}
        >
          <AiOutlineLogout />
        </div>
      </div>
    </Wrapper>
  );
}

const NotiSection = () => {
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);

  const goPage = () => {
    history.push("/news");
  };

  return (
    <NotiWrap>
      <h3>Notification</h3>
      <div className="noti-body">
        {myUser && myUser.latest_news && (
          <>
            {myUser.latest_news.map((el) => (
              <div className="noti-item">
                <div className="noti-item-head">
                  <div className="noti-item-author">
                    &nbsp;
                  </div>
                  <div className="noti-item-date">
                    {asDateTime(el.created_at)}
                  </div>
                </div>
                <div className="noti-item-body">
                  <p>{el.title}</p>
                  {/* el.image && <img src={el.image} /> */}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="noti-footer">
        <a href="#" onClick={goPage}>
          See All
        </a>
      </div>
    </NotiWrap>
  );
};

const Wrapper = styled.div`
  padding-top: 15px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: transparent;
  @media (max-width: 768px) {
    line-height: 1.2em;
  }
  .title-root {
    display: flex;
    align-items: center;
    .mobile-side-icon {
      display: none;
      font-size: 24px;
      color: ${props=>props.theme.palette.title};
      opacity: 0.5;
      margin-right: 8px;
    }
    .mobile-collapse-icon {
      display: inline;
    }
    @media (max-width: 900px) {
      .mobile-side-icon {
        display: inline;
      }
      .mobile-collapse-icon {
        display: none;
      }
    }
    .page-title {
      display: flex;
      align-items: center;
      .subtitle {
        color: ${props=>props.theme.palette.title};
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
        margin: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .user-first-name {
        margin: 0px;
        color: ${props=>props.theme.palette.title};
        font-size: 24px;
        font-weight: 600;
      }
      h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: ${props=>props.theme.palette.title};
        margin: 0;
        &.mobile-title {
          display: none;
        }
        @media (max-width: 768px) {
          font-size: 20px;
          &.mobile-title {
            display: block;
          }
          &.desktop-title {
            display: none;
          }
        }
      }
    }
  }
  .avatar-root {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: flex-end;
    }
    .noti-wrap {
      margin-right: 20px;
      &.noti-mobile-wrap {
        display: none;
      }
      @media (max-width: 768px) {
        display: none;
        &.noti-mobile-wrap {
          display: block;
        }
      }
      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        @media (max-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 25px;
      background: ${props=>props.theme.palette.hr};
      margin-right: 20px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .photo-wrap {
      margin-right: 20px;
      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border: 3px solid #c1954a;
        border-radius: 50%;
        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .logout-wrap {
      button {
        border-radius: 12px;
        background: ${props=>props.theme.palette.outBtnBg};
        color: ${props=>props.theme.palette.outBtnText};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        padding: 15px 50px;
        text-transform: capitalize;
        line-height: 1em;
        border: 0 none;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .logout-mobile-wrap {
      display: none;
      color: ${props=>props.theme.palette.text};
      font-size: 24px;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  .back-btn {
    background: #c1954a1a;
    color: #c1954a;
    padding: 6px 12px;
    font-size: 12px;
    cursor: pointer;
    line-height: 1.2em;
    margin-right: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .theme-select {
    .ant-switch.ant-switch-checked {
      background-color: ${props=>props.theme.palette.primary};
    }
    .ant-switch.ant-switch-unchecked {
      background-color: ${props=>props.theme.palette.secondary};
    }
    .ant-switch-inner svg {
      font-size: 18px;
      margin-bottom: 4px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const NotiWrap = styled.div`
  border-radius: 20px;
  border: 1px solid ${props=>props.theme.palette.modalBorder};
  background: ${props=>props.theme.palette.modalBg};
  width: 420px;
  h3 {
    font-size: 18px;
    color: ${props=>props.theme.palette.text};
    border-bottom: 1px solid ${props=>props.theme.palette.hr};
    padding: 20px;
  }
  .noti-body {
    padding: 0 20px;
    .noti-item {
      color: ${props=>props.theme.palette.text};
      border-bottom: 1px solid ${props=>props.theme.palette.hr};
      padding: 15px 0;
    }
    .noti-item-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .noti-item-author {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        color: ${props=>props.theme.palette.text};
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      .noti-item-date {
        color: ${props=>props.theme.palette.text};
        font-size: 12px;
      }
    }
    .noti-item-body {
      p {
        color: ${props=>props.theme.palette.secondaryText};
        font-size: 14px;
        font-weight: 400;
      }
      img {
        max-width: 100%;
        margin: 10px 0 0;
        border-radius: 20px;
      }
    }
  }
  .noti-footer {
    border-radius: 0 0 20px 20px;
    background: ${props=>props.theme.palette.notiFootBg};
    text-align: center;
    margin: 15px 0 0;
    padding: 20px;
    a {
      position: relative;
      color: ${props=>props.theme.palette.link};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      padding: 0;
    }
  }
`;
