import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Layout, Menu, Switch } from "components/common";
import { getMenus, hasPageAccess } from "common/menu";
import { getSudo, getToken } from "utils/auth";
import LogoImg from "assets/logo2.png";
import WhLogoImg from "assets/logo3.png";
import SmallLogoImg from "assets/logo.png";
import { ReactComponent as ArrowDownIcon } from "assets/Arrow-Down.svg";
import { ReactComponent as WhArrowDownIcon } from "assets/Arrow-Down_wh.svg";
import FacebookImg from "assets/facebook.png";
import TelegramImg from "assets/twitter.png";
import { MdDarkMode, MdLightMode } from "react-icons/md";

const { SubMenu } = Menu;
const { Sider } = Layout;

export default function Sidebar({ width }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const sudoName = getSudo();
  const token = getToken();
  const myUser = useSelector((state) => state.auth.user);
  const isCollapse = useSelector((state) => state.ui.isCollapse);
  const isHoverSidebar = useSelector((state) => state.ui.isHoverSidebar);
  const theme = useSelector(state => state.ui.theme)

  const onMenuClick = (e) => {
    if (e.key === "/shop" && token) {
      window.open(
        `${process.env.REACT_APP_CORP_URL}/login?auto-token-login&pathName=shop&token=${token}`,
        "_blank"
      );
    } else {
      history.push(e.key);
    }
  };

  const onChangeTheme = (checked) => {
    if (checked) {
      dispatch({
        type: 'ui.SET_THEME',
        payload: {
          theme: 'dark'
        }
      })
    } else {
      dispatch({
        type: 'ui.SET_THEME',
        payload: {
          theme: 'light'
        }
      })
    }
  }

  const useWindowDimensions = () => {
    const hasWindow = typeof window !== "undefined";

    function getWindowDimensions() {
      const height = hasWindow ? window.innerHeight : null;
      const width_ = hasWindow ? window.innerWidth : null;
      return {
        height,
        width_,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const { height, width_ } = useWindowDimensions();

  useEffect(() => {
    if (width_ < 900) {
      dispatch({
        type: "ui.SET_COLLAPSE",
        payload: {
          isCollapse: false,
        },
      });
    }
  }, [width_]);

  return (
    <SiderWrapper
      onMouseEnter={() => {
        dispatch({
          type: "ui.SET_HOVER_SIDEBAR",
          payload: {
            isHoverSidebar: true,
          },
        });
      }}
      onMouseLeave={() => {
        dispatch({
          type: "ui.SET_HOVER_SIDEBAR",
          payload: {
            isHoverSidebar: false,
          },
        });
      }}
      className={
        isCollapse && !isHoverSidebar
          ? "sidebar-wrapper sidebar-collapsed"
          : "sidebar-wrapper"
      }
      style={{ width: width || "270px" }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={isHoverSidebar ? false : isCollapse}
      >
        {isCollapse && !isHoverSidebar ? (
          <div className="logo small-logo">
            <img src={SmallLogoImg} />
          </div>
        ) : (
          <div className="logo">
            <img src={theme === 'dark' ? LogoImg : WhLogoImg} />
          </div>
        )}
        <div className={`menu-container theme-${theme}`}>
          <Menu
            theme={"dark"}
            mode="inline"
            expandIcon={isCollapse
              ? null
              : (theme === 'dark'
                ? <ArrowDownIcon />
                : <WhArrowDownIcon />
              )
            }
            defaultSelectedKeys={[history?.location?.pathname]}
            selectedKeys={[history?.location?.pathname]}
            onClick={onMenuClick}
            triggerSubMenuAction={"click"}
            style={{
              height: width_ < 768 ? height - 110 : height - 150,
            }}
          >
            {getMenus(myUser).filter((item) => {
              return hasPageAccess(item.route, myUser, sudoName);
            }).map((menu) =>
              menu.submenus && menu.submenus.length > 0 ? (
                <SubMenu icon={menu.icon} key={menu.route} title={menu.label}>
                  {menu.submenus
                    .filter((item2) => {
                      return hasPageAccess(item2.route, myUser, sudoName);
                    })
                    .map((submenu) => (
                      <Menu.Item key={submenu.route}>
                        <span>
                          {history?.location?.pathname === submenu.route && (
                            <div className="left-border" />
                          )}
                          <span>{submenu.label}</span>
                        </span>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.route} icon={menu.icon}>
                  <span>
                    {history?.location?.pathname === menu.route && (
                      <div className="left-border" />
                    )}
                    <span>{menu.label}</span>
                  </span>
                </Menu.Item>
              )
            )}
            <div className='theme-select'>
              <span className='title-span'>
                Theme:&nbsp;
              </span>
              <Switch checkedChildren={<MdDarkMode />}
                unCheckedChildren={<MdLightMode />}
                checked={theme === 'dark'}
                onChange={onChangeTheme}
              />
            </div>
            <div className="menu-divider" />
            <div className="connect-container d-flex" style={{alignItems:"center", gap:"10px"}}>
              <h4>Join Our Community
              </h4>
              <li className={"app_link_li"}>
                <img
                  src={TelegramImg}
                  alt={"Telegram"}
                  className={"app_link"}
                  onClick={() => {
                    window.open("https://akashxcommunity.com", "_blank");
                  }}
                />
              </li>
            </div>
          </Menu>
        </div>
      </Sider>
    </SiderWrapper>
  );
}

const SiderWrapper = styled.div`
  position: fixed;
  height: calc(100% - 40px);
  width: 270px;
  z-index: 100;
  transition: width 0.7s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  .connect-container {
    padding: 20px 25px;
    margin: 20px 0 0 0;
    h4 {
      font-weight: 600;
      font-size: 18px;
      color: ${props => props.theme.palette.title};
    }
    ul {
      display: flex;
    }
  }
  .divider {
    height: 1px;
    background: #202020;
    margin: 5px 0px 5px -15px;
    width: calc(100% + 20px);
  }
  .left-border {
    position: absolute;
    background: #c1954a;
    top: 25px;
    width: 5px;
    height: 5px;
    right: 0px;
    border-radius: 50%;
  }
  .connect-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #ffffff;
    opacity: 0.25;
    margin: 44px 0px 20px 30px;
  }
  .disable-connect-text {
    margin: 10px 0px 20px 15px;
    visibility: hidden !important;
  }
  .menu-divider {
    height: 1px;
    position: absolute;
    background: ${props => props.theme.palette.hr};
    margin: 15px 0px;
    width: 100%;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .ant-layout-sider {
    font-family: "Roboto", sans-serif;
    width: 100% !important;
    max-width: 100% !important;
    background: ${props => props.theme.palette.menuBg};
    border: 1px solid ${props => props.theme.palette.menuBorder};
    border-radius: 20px;
    padding-right: 5px;
    /* padding: 0px 5px 0px 0px; */
    // margin-left: 20px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background: transparent;
    }
    .ant-menu-item {
      padding: 26px 0px 26px 12px !important;
      margin: 0px;
      color: ${props => props.theme.palette.menuText};
      width: calc(100% - 7px);
    }
    .ant-menu-item-icon {
      width: 23px;
    }
    .ant-menu-submenu-expand-icon {
      width: 14px;
      opacity: 0.5;
    }
    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 400;
      color: ${props => props.theme.palette.menuText};
      margin-left: 15px;
    }
    .ant-menu-item-icon {
      position: relative;
      top: -1px;
      margin-left: 10px;
      path {
        fill: #8d8d8d !important;
        stroke: #8d8d8d !important;
      }
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      background-color: transparent;
      .ant-menu-title-content {
        color: ${props => props.theme.palette.menuActiveText} !important;
        font-size: 16px;
        opacity: 1;
        margin-left: 15px;
      }
      .ant-menu-item-icon {
        path {
          fill: ${props => props.theme.palette.menuActiveText} !important;
          stroke: ${props => props.theme.palette.menuActiveText} !important;
        }
      }
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-submenu-selected {
      .ant-menu-submenu-title .ant-menu-title-content {
        color: ${props => props.theme.palette.menuActiveText} !important;
        font-weight: 600;
        font-size: 16px;
        opacity: 1;
        margin-left: 15px;
      }
      .ant-menu-item-icon {
        path {
          fill: ${props => props.theme.palette.menuActiveText} !important;
          stroke: ${props => props.theme.palette.menuActiveText} !important;
        }
      }
    }
    .ant-menu-root {
      overflow-y: auto;
      padding-right: 4px;
      scrollbar-color: #e5e5e580 #1d1d1dc0;
      scrollbar-width: thin !important;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 5px transparent;
        background: #1d1d1dc0;
      }
      &::-webkit-scrollbar-track:hover {
        background: #1d1d1dc0;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5e5e580;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #e5e5e580;
      }
    }
    .menu-container.theme-light {
      .ant-menu-root {
        scrollbar-color: #c4c4c4ef #fff;
        &::-webkit-scrollbar-track {
          background: #fff;
        }
        &::-webkit-scrollbar-track:hover {
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: #c4c4c4ef;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #c4c4c4ef;
        }
      }
    }

    .ant-menu-submenu {
      color: ${props => props.theme.palette.menuText};
      .ant-menu-item {
        padding-left: 45px !important;
      }
      .ant-menu-submenu-title {
        color: ${props => props.theme.palette.menuText};
        padding: 23px 0px 23px 12px !important;
        margin: 0px;
        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow {
          right: 10px;
        }
      }
    }
    .menu-container {
      padding-bottom: 15px;
    }
    .logo {
      display: flex;
      align-items: center;
      padding-left: 22px;
      /* justify-content: center; */
      width: 100%;
      margin: 14px 0px 20px;
      svg {
        width: 150px;
        height: 80px;
      }
      img {
        width: 200px;
        margin-top: 12px;
      }
    }
    .small-logo {
      margin: 14px 0px 20px 4px;
      img {
        width: 47px !important;
      }
    }
  }
  .ant-menu-sub {
    .ant-menu-title-content {
      margin-left: 20px;
    }
  }
  .ant-menu-sub {
    .ant-menu-item-selected {
      .ant-menu-title-content {
        margin-left: 20px !important;
      }
    }
  }
  .app_link_li {
    margin: 8px 0px 8px 0;
    display: flex;
    justify-content: center;
  }
  .app_link {
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }
  .theme-select {
    margin-top: 12px;
    padding: 12px 0px 12px 24px !important;
    display: none;
    align-items: center;
    @media (max-width: 768px) {
      display: flex;
    }
    .title-span {
      color: ${props => props.theme.palette.menuText};
      margin-right: 8px;
      font-size: 16px;
    }
    .ant-switch.ant-switch-checked {
      background-color: ${props => props.theme.palette.primary};
    }
    .ant-switch.ant-switch-unchecked {
      background-color: ${props => props.theme.palette.secondary};
    }
    .ant-switch-inner svg {
      font-size: 18px;
      margin-bottom: 4px;
    }
  }
`;
