import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { uiVarList } from 'common/uiVar';
import { 
  Select2, Spin, DisclaimerBox 
} from 'components/common';
import { callGetApiWithAuth } from 'utils/api';
import NoPhotoImg from 'assets/no_image.png'

export default function SchedulePage() {
  const [listData, setListData] = useState([])
  const [totalCnt, setTotalCnt] = useState(0)
  const [searchParam, setSearchParam] = useState({
    educator_id: '',
    language_id: '',
    timezone: 'America/Los_Angeles',  // utc name, ex: America/Los_Angeles
    timezone2: 'Pacific Daylight Time', // title, ex: Pacific Daylight Time
    timezone3: 'PDT', // abbr, ex: PDT
  })
  const [isGotTz, setIsGotTz] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [educators, setEducators] = useState([])
  const [languages, setLanguages] = useState([]);

  const onGetEducators = ({ data }) => {
    setEducators(data)
  }

  const onGetLanguages = ({ data }) => {
    setLanguages(data)
  }
  const onGetListData = ({ data }) => {
    let totalCnt0 = 0;
    data.forEach(el => {
      el.sessions.forEach(el2 => {
        totalCnt0++;
      })
    })
    setTotalCnt(totalCnt0);
    setListData([...data])
    setIsLoading(false)
  }
  const onFailListData = () => {
    setIsLoading(false)
  }
  const loadListData = () => {
    setIsLoading(true)
    const params = {
      ...searchParam,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&');
    callGetApiWithAuth(`akashx/stream/schedule?${q}`, 
      onGetListData, onFailListData
    )
  }
  const handleSearch = () => {
    loadListData()
  }

  useEffect(() => {
    callGetApiWithAuth(`akashx/common/educators`, onGetEducators)
    callGetApiWithAuth(`akashx/common/languages`, onGetLanguages)
    const loTz = moment.tz.guess();
    uiVarList('timezones.list').forEach(el => {
      el.utc.forEach(el2 => {
        if (el2 === loTz) {
          setSearchParam({
            ...searchParam,
            timezone: el2,
            timezone2: el.value,
            timezone3: el.abbr
          })
        }
      })
    })
    setIsGotTz(true)
  }, [])

  useEffect(() => {
    if (isGotTz) {
      handleSearch()
    }
  }, [searchParam, isGotTz])

  return (
    <Wrapper>
      <div className='top-bar'>
        <h2 className='page-title'>
          Total Sessions ({totalCnt})
        </h2>
        <div className='search-wrap'>
        <div className='search-field'>
            <label>Language:</label>
            <Select2 value={searchParam.language_id}
              options={[
                { label: 'Select Language', value: '' },
                ...languages.map(el => ({
                  label: `${el.title}`,
                  value: el.id
                }))
              ]}
              onChange={e => {
                let searchParam0 = {...searchParam, language_id: e.target.value};
                setSearchParam(searchParam0)
              }}
              style={{ minWidth: 180 }}
            />
          </div>
          <div className='search-field'>
            <label>Educator:</label>
            <Select2 value={searchParam.educator_id}
              options={[
                { label: 'Select Educator', value: '' },
                ...educators.map(el => ({
                  label: `${el.first_name} ${el.last_name}`,
                  value: el.id
                }))
              ]}
              onChange={e => {
                let searchParam0 = {...searchParam, educator_id: e.target.value};
                setSearchParam(searchParam0)
              }}
              style={{ minWidth: 180 }}
            />
          </div>
          <div className='search-field'>
            <label>Timezone:</label>
            <Select2 value={searchParam.timezone2}
              options={uiVarList('timezones.list').map(el => ({
                label: el.value,
                value: el.value
              }))}
              onChange={e => {
                let searchParam0 = {...searchParam, timezone2: e.target.value};
                uiVarList('timezones.list').forEach(el => {
                  if (el.value === e.target.value) {
                    searchParam0.timezone = el.utc[0];
                    searchParam0.timezone3 = el.abbr;
                  }
                })
                setSearchParam(searchParam0)
              }}
              style={{ minWidth: 200 }}
              />
          </div>
        </div>
      </div>
      <div className='list-root'>
        {listData.map(el => 
        <div className='group-root'>
          <h2>{el.label}</h2>
          <div className='card-list'>
            {el.sessions.map(el2 => (
              <StreamCard data={el2} />
            ))}
          </div>
        </div>
        )}
        {isLoading && 
        <div className='spin-wrap'>
          <Spin spinning={true} />
        </div>
        }
      </div>
      <div style={{ height: 12 }} />
      <DisclaimerBox />
    </Wrapper>
  )
}

const StreamCard = ({ data }) => {
  const history = useHistory();

  const goDetail = () => {
    history.push(`/stream/${data.id}`);
  }

  return (
    <div className='card-wrap' onClick={goDetail}>
      <div className='card-img'>
        <img src={data.educator?.image || NoPhotoImg} />
      </div>
      <div className='name-span'>
        {data.educator?
          data.educator.first_name+' '+data.educator.last_name
        : ''}
      </div>
      <div className='spec-span'>
        {data.educator?.speciality}
      </div>
      <p className='desc-span'
        dangerouslySetInnerHTML={{
          __html: data.description
        }}
      />
      <span className='time-span'>
        Start at:&nbsp;{data.hour}
      </span>
    </div>
  )
}

const Wrapper = styled.div`
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EFEDF91a;
    padding-bottom: 12px;
    flex-wrap: wrap;
    .page-title {
      font-size: 18px;
      font-weight: 500;
      color: #DAA35A;
      margin: 0;
    }
    .search-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      @media (max-width: 768px) {

      }
    }
    .search-field {
      display: flex;
      align-items: center;
      margin: 0 8px;
      label {
        margin-right: 8px;
        color: ${props=>props.theme.palette.text};
      }
      .ant-select {
        width: 100%;
      }
      @media (max-width: 1024px) {
        width: 100%;
        justify-content: space-between;
        label {
          width: 150px;
        }
        .select2-root {
          width: calc(100% - 170px);
        }
      }
      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 8px;
        label {
          width: 70px;
        }
        .select2-root {
          width: calc(100% - 80px);
        }
      }
    }
  }
  .list-root {
    position: relative;
    min-height: 600px;
  }
  .spin-wrap {
    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .group-root {
    margin: 20px 0 40px 0;
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: ${props=>props.theme.palette.text};
      margin: 0 0 18px 0;
    }
  }
  .card-list {
    display: flex;
    flex-wrap: wrap;
  }
  .card-wrap {
    width: calc(25% - 20px);
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    background: ${props=>props.theme.palette.cardBg};
    border-radius: 20px;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
    @media (max-width: 1600px) {
      width: calc(33% - 20px);
    }
    @media (max-width: 1366px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .card-img {
      margin-bottom: 20px;
      cursor: pointer;
      padding-top: 56.25%;
      width: 100%;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
      }
    }
    .name-span {
      color: ${props=>props.theme.palette.text};
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
    .spec-span {
      color: #DAA35A;
      font-size: 12px;
      padding: 6px 0;
    }
    .desc-span {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 88px;
      p {
        margin: 0;
        color: ${props=>props.theme.palette.secondaryText};
      }
    }
    .time-span {
      background: #C1954A1A;
      color: #C1954A;
      padding: 6px 12px;
      font-size: 12px;
      border-radius: 12px;
    }
  }
`
