import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Spin, DisclaimerBox } from "components/common";
import UpgradeModal from "components/upgrade/UpgradeModal";

export default function RhythmPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <Wrapper>
      {true ? (
        <div className="spinner-container">
          <Spin spinning={true} className={`spinner`} size="large" />
        </div>
      ) : (
        <>
          <div style={{ height: "500px" }}>
            <iframe src="/ext/rhythm.html" width="100%" height="100%" />
          </div>
          <div style={{ height: "24px" }} />
          <DisclaimerBox />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .upgrade-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    font-size: 16px;
  }
`;
