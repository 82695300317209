import { Select as Select_ } from "antd";
import styled from "styled-components";

export const { Option } = Select_;
export const Select = ({ className, ...props }) => {
  return (
    <Wrapper className="select-wrapper">
      <Select_ {...props} className={`selectContainer ${className}`} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .input {
    min-height: 34px;
  }
  .ant-select-selector {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 10px !important;
    height: 40px !important;
    padding-top: 3px !important;
  }
`;
