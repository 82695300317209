import { Table as Table0 } from "antd";
import styled from "styled-components";

export const Table = styled(Table0)`
  .ant-table {
    border-radius: 7px;
    thead {
      tr {
        th::before {
          background: none !important;
        }
        th {
          background: #f9f9fa;
          font-weight: 400;
          font-size: 14px;
          color: #696e7e99;
          padding: 14px 0px;
        }
        th:first-child {
          border-radius: 7px 0px 0px 0px !important;
        }
        th:last-child {
          border-radius: 0px 7px 0px 0px !important;
        }
      }
    }
  }

  .ant-table-thead > tr > th {
    color: ${(props) => props.theme.palette.secondaryText};
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th {
    padding: 8px;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > td {
    padding: 16px 8px;
  }
  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child,
  .ant-table tfoot > tr > th:first-child,
  .ant-table tfoot > tr > td:first-child {
    padding-left: 20px;
  }
  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child,
  .ant-table tfoot > tr > th:last-child,
  .ant-table tfoot > tr > td:last-child {
    padding-right: 20px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
  }
`;
