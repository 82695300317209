import styled from 'styled-components';

export const Wrapper = styled.div`
  .top-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    flex-wrap: wrap;
  }
  .title-wrap {
    color: #DAA35A;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
  .search-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    // select {
    //   background: rgba(13,13,13,0.20);
    //   border: 1px solid #EFEDF91A;
    //   border-radius: 8px;
    //   color: #EFEDF9;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 400;
    //   margin: 0 8px;
    //   padding: 8px 12px;
    // }
    button {
      border-radius: 8px;
      background: rgba(193, 149, 74, 0.10);
      color: #C1954A;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      border: 0;
      padding: 12px 34px;
      margin-left: 8px;
    }
    @media (max-width: 1024px) {
      justify-content: flex-start;
      select {
        margin-top: 4px; 
        margin-bottom: 4px;
      }
      button {
        margin-top: 4px; 
        margin-bottom: 4px;
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      justify-content: flex-start;
      select {
        margin-top: 4px; 
        margin-bottom: 4px;
      }
      button {
        margin-top: 4px; 
        margin-bottom: 4px;
        width: 100%;
      }
    }
  }
  .table-wrap {
    position: relative;
    min-height: 480px;
  }
  .spin-wrap {
    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
  }
  .empty-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination-wrap {
    margin: 12px 0;
    display: flex;
    justify-content: flex-end;
    .ant-pagination-prev .ant-pagination-item-link, 
    .ant-pagination-next .ant-pagination-item-link {
      background: transparent;
      border-color: transparent;
      color: #EFEDF9;
    }
    .ant-pagination-disabled .ant-pagination-item-link, 
    .ant-pagination-disabled:hover .ant-pagination-item-link, 
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
      color: rgba(255, 255, 255, 0.5);
    }
    .ant-pagination-item {
      background: transparent;
      border-color: transparent;
      color: #EFEDF9c0;
    }
    .ant-pagination-item-active {
      color: #DAA35A;
    }
  }
  .card-wrap {
    border-radius: 20px;
    border: 1px solid ${props=>props.theme.palette.cardBorder};
    background: ${props=>props.theme.palette.cardBg};
    padding: 20px;
    margin-bottom: 30px;
    position: relative;
    .ant-rate-star:not(:last-child) {
      margin-right: 2px;
    }
    .stat-wrap {
      position: absolute;
      top: -12px;
      left: 20px;
      color: #EFEDF9;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 8px;
      padding: 4px 8px;
      background-color: #52C41A;
      &.inactive {
        background-color: #E92626;
      }
    }
    .tp-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .pa-wrap {
        display: flex;
        align-items: center;
      }
      .pair-img {
        margin-right: 18px;
        img {
          width: 70px;
          height: 35px;
          object-fit: cover;
        }
      }
      .pair-name {
        margin-right: 18px;
        color: ${props=>props.theme.palette.text};
        font-size: 14px;
        font-weight: 600;
      }
      .type-span {
        border-radius: 50px;
        background: #8d8d8d;
        border: 0;
        color: #EFEDF9;
        font-size: 12px;
        font-weight: 600;
        padding: 5px 13px;
        margin: 0 0 0 10px;
        &.buy {
          background-color: #52C41A;
        }
        &.sell {
          background-color: #E92626;
        }
        &.marketing {
          background-color: #3974E8;
        }
      }
      .ep-wrap {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: flex-start;
          margin-top: 8px;
        }
        .ep-span {
          margin: 4px 0;
          padding: 4px 24px;
          color: ${props=>props.theme.palette.epSpanText};
          background-color: ${props=>props.theme.palette.epSpanBg};
          font-weight: bold;
          border-radius: 6px;
          @media (max-width: 768px) {
            margin-bottom: 8px;
            margin-right: 4px;
          }
          label {
            margin-right: 4px;
          }
        }
        .ep-split {
          width: 1px;
          height: 24px;
          margin: 8px 12px;
          background: #C1954A;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    .bt-line {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 8px;
      .lf-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          margin: 8px 0;
        }
      }
      .edu-wrap {
        display: flex;
        align-items: center;
        margin-right: 16px;
        @media (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
        }
        span {
          font-size: 14px;
          color: ${props=>props.theme.palette.text};
          font-weight: 600;
          margin: 0 0 0 6px;
        }
      }
      .ty-wrap {
        margin-right: 16px;
        color: ${props=>props.theme.palette.text};
        label {
          color: ${props=>props.theme.palette.secondaryText};
          margin-right: 4px;
        }
      }
      .rg-wrap {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          flex-wrap: wrap;
        }
      }
      .note-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 768px) {
          margin-top: 12px;
        }
        label {
          color: #DAA35A;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-right: 16px;
        }
        p {
          color: ${props=>props.theme.palette.secondaryText};
          text-align: justify;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.2em;
          margin-right: 16px;
          margin-bottom: 0;
          max-width: 500px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .note-img {
        img {
          width: 60px;
          height: 30px;
          object-fit: cover;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 12px;
        }
      }
      .act-wrap {
        @media (max-width: 768px) {
          margin: 8px 0;
        }
        button {
          border-radius: 8px;
          background: rgba(193, 149, 74, 0.10);
          color: #C1954A;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1em;
          text-transform: capitalize;
          border: 0;
          width: 130px;
          padding: 12px 34px;
          margin-top: 12px;
        }
      }
    }
  }
`
