
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { 
  TablePanel, 
  UserAvatar, CountryFlag, 
} from "components/common";
import { callGetApiWithAuth } from 'utils/api';
import { asPrice } from 'utils/text';

export default function FollowerLbdPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => {
    setTableData(data)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`akashx/leaderboard/list_by_followers`,
      onGetTableData,
      onFailTableData,
    )
  }
  const handleSearch = () => {
    loadTableData()
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel title={'Followers'}
        data={tableData}
        loading={isLoading}
        columns={[
          {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
              <UserAvatar
                title={`${record.user.first_name} ${record.user.last_name}`}
                image={record.user.image}
              />
            )
          },
          {
            title: 'Country',
            key: 'country',
            render: (_, record) => (
              <CountryFlag 
                country={record.user.country}
              />
            )
          },
          {
            title: 'Total Trades',
            key: 'all_count',
            render: (_, record) => (
              <span>
                {record.all_count}
              </span>
            )
          },
          {
            title: 'Win Ratio',
            key: 'won_ratio',
            render: (_, record) => (
              <span>
                {record.won_ratio}%
              </span>
            )
          },
          {
            title: 'Total Profit',
            key: 'profit',
            render: (_, record) => (
              <span>
                {asPrice(record.profit)}
              </span>
            )
          },
          {
            title: 'Total Growth',
            key: 'growth',
            render: (_, record) => (
              <span>
                {record.growth}%
              </span>
            )
          },
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .cardRoot {
    color: ${props=>props.theme.palette.text};
    border-radius: 15px;
    background: ${props=>props.theme.palette.cardBg};
  }
  .panelHead {
    display: none;
  }
  .tableWrapper {
    margin-top: -20px;
  }
  .ant-table thead tr th {
    background: #2d2921;
    color: ${props=>props.theme.palette.th};
  }
  .ant-table {
    color: ${props=>props.theme.palette.text};
    background-color: transparent;
  }
  .tableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
    opacity: 0.8;
  }
  .ant-table-thead > tr > th {
    border-color: #efedf91a;
  }
  .ant-table-tbody > tr > td {
    border-color: #efedf91a;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
  }
  .ant-table-placeholder:hover td {
    background: transparent !important;
  }
`
