import React, { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  Popover, CountryFlag
} from 'components/common';

export default function LangBadge() {
  const [curLang, setCurLang] = useState({
    country: 'US',
    lang: 'en',
    label: 'English'
  });
  const langOptions = [
    {
      country: 'US',
      lang: 'en',
      label: 'English'
    },
    {
      country: 'CZ',
      lang: 'cs',
      label: 'Čeština'
    },
    {
      country: 'DE',
      lang: 'de',
      label: 'Deutsch'
    },
    {
      country: 'ES',
      lang: 'es',
      label: 'Español'
    },
    {
      country: 'FR',
      lang: 'fr',
      label: 'Français'
    },
    {
      country: 'HR',
      lang: 'hr',
      label: 'Hrvatski'
    },
    {
      country: 'HU',
      lang: 'hu',
      label: 'Magyar'
    },
    {
      country: 'IT',
      lang: 'it',
      label: 'Italiano'
    },
    {
      country: 'JP',
      lang: 'ja',
      label: '日本語'
    },
    {
      country: 'MN',
      lang: 'mn',
      label: 'монгол'
    },
    {
      country: 'NL',
      lang: 'nl',
      label: 'Nederlands'
    },
    {
      country: 'PL',
      lang: 'pl',
      label: 'Polski'
    },
    {
      country: 'PT',
      lang: 'pt',
      label: 'Português'
    },
    {
      country: 'RO',
      lang: 'ro',
      label: 'Română'
    },
    {
      country: 'RU',
      lang: 'ru',
      label: 'Русский'
    },
    {
      country: 'SK',
      lang: 'sk',
      label: 'Slovenčina'
    },
    {
      country: 'SE',
      lang: 'sv',
      label: 'Svenska'
    },
    {
      country: 'TH',
      lang: 'th',
      label: 'ไทย'
    },
    {
      country: 'TR',
      lang: 'tr',
      label: 'Türkçe'
    },
    {
      country: 'CN',
      lang: 'zh-Hans',
      label: '中文'
    },
    {
      country: 'TW',
      lang: 'zh-TW',
      label: '中文(繁體)'
    },
  ];

  useEffect(() => {
    window.Localize.setLanguage(curLang.lang)
    window.Localize.translatePage()
  }, [curLang])

  useEffect(() => {
    window.Localize.getAvailableLanguages(function(err, languages) {
      if (err) return console.log(err);
    });
  }, [])

  return (
    <>
      <Popover 
        overlayClassName={'topLangPopover'}
        placement='bottom'
        trigger="click"
        content={
          <div className='topLangOptions'>
            {langOptions.map(el => (
              <div className={`d-flex align-items-center ${curLang.lang===el.lang?'active':''}`}
                onClick={() => setCurLang(el)}
              >
                <CountryFlag country={el.country} />
                <span style={{ width: 3 }} />
                <span>
                  {el.label}
                </span>
              </div>
            ))}
          </div>
        }
      >
        <div className='topLangBar'>
          <CountryFlag country={curLang.country} />
        </div>
      </Popover>
      <GlobalStyle />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  .topLangBar {
    cursor: pointer;
    padding: 0 12px;
    .imageContainer {
      width: 34px !important;
      height: 24px !important;
      border-radius: 2px;
      @media (max-width: 768px) {
        width: 28px !important;
        height: 20px !important;
      }
    }
  }
  .topLangOptions {
    cursor: pointer;
    width: 150px;
    height: 450px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 5px transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #e4e4e4;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #e4e4e4;
    }
    > div {
      padding: 10px 8px;
      margin-right: 8px;
      &.active {
        border-radius: 8px;
        background: rgba(145, 158, 171, 0.16);;
      }
      .imageContainer {
        width: 28px !important;
        height: 20px !important;
        border-radius: 2px;
      }
    }
  }
  .topLangPopover {
    .ant-popover-inner-content {
      padding-right: 4px;
    }
  }
`
