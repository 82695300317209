export const theme = {
  palette: {
    primary: "#c1954a",
    secondary: "#efedf9",
    text: "#efedf9",
    secondaryText: '#EFEDF980',
    link: '#c1954a',
    pending: "#00b2ff",
    success: "#52c41a",
    warning: "#faad14",
    error: "#ff4d4f",
    rootBg: '#0d0d0d',
    menuBg: '#0d0d0d',
    menuBorder: 'rgba(255, 255, 255, 0.1)',
    menuText: '#e5e5e580',
    menuActiveText: '#c1954a',
    title: '#efedf9',
    cardBorder: '#efedf91a',
    cardBg: '#1d1d1d',
    outBtnBg: 'rgba(193, 149, 74, 0.1)',
    outBtnText: '#c1954a',
    submitBtnBg: 'rgba(193, 149, 74, 0.1)',
    submitBtnText: '#c1954a',
    modalBg: '#1d1d1d',
    modalBorder: 'rgba(255, 255, 255, 0.1)',
    hr: '#4c4c4c',
    notiFootBg: '#2d2921',
    chartBg: '#0d0d0d',
    chartBorder: 'transparent',
    inputBg: 'rgba(13, 13, 13, 0.2)',
    inputBorder: 'rgba(255, 255, 255, 0.1)',
    th: '#efedf9',
    emptyTd: '#333232',
    select2Bg: '#0d0d0d',
    select2Border: '#efedf91a',
    select2Arr: '#fff',
    epSpanText: '#fff',
    epSpanBg: '#0d0d0d',
  },
};

export const liTheme = {
  palette: {
    primary: "#c1954a",
    secondary: "#1D1D1D",
    text: "#1D1D1D",
    secondaryText: '#1D1D1D80',
    link: '#c1954a',
    pending: "#00b2ff",
    success: "#52c41a",
    warning: "#faad14",
    error: "#ff4d4f",
    rootBg: '#F3F5F8',
    menuBg: '#fff',
    menuBorder: '#1D1D1D20',
    menuText: '#7C7C7C',
    menuActiveText: '#c1954a',
    title: '#1D1D1D',
    cardBorder: '#1D1D1D22',
    cardBg: '#fff',
    outBtnBg: '#DAA35A',
    outBtnText: '#060610',
    submitBtnBg: '#DAA35A',
    submitBtnText: '#060610',
    modalBg: '#fff',
    modalBorder: '#1D1D1D22',
    hr: '#1D1D1D20',
    notiFootBg: '#0B0B0B',
    chartBg: 'transparent',
    chartBorder: '#1D1D1D22',
    inputBg: 'rgba(255, 255, 255, 0.1)',
    inputBorder: '#0D0D0D33',
    th: '#DAA35A',
    emptyTd: '#cecece',
    select2Bg: 'transparent',
    select2Border: '#1D1D1D20',
    select2Arr: '#0d0d0d',
    epSpanText: '#1D1D1D',
    epSpanBg: 'transparent',
  },
};