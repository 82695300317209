import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Row, Col,
  VideoModal,
  Spin, DisclaimerBox
} from "components/common";
import UpgradeModal from "components/upgrade/UpgradeModal";
import { Alert } from "antd";

export default function GoldenRainPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalMode, setModalMode] = useState(undefined)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1200);
  }, []);

  return (
    <Wrapper>
      {!isLoaded ? (
        <div className="spinner-container">
          <Spin spinning={true} className={`spinner`} size="large" />
        </div>
      ) : (
        <>
          {/* <div style={{ height: "600px" }}>
            <iframe src="/ext/golden_rain.html" width="100%" height="100%" />
          </div> */}
          <Alert
            style={{
              margin: "5px 0px 5px",
              alignItems: "baseline",
              borderRadius: "8px",
            }}
            message={
              <div className="rank-alert">
                <span>{"Golden Rain is currently being optimized and is under maintenance. Please check back soon."
                }</span>
              </div>
            }
            type={"warning"}
            showIcon
          />
          <div style={{ height: 24 }} />
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} lg={12} xl={12} xxl={8}>
              <div className="stvid-card">
                <div className="img-wrap">
                  <img src={`https://d2511r1bjh2ay3.cloudfront.net/tool/wt54o1GZnMjZbo8UNF7nPym8z21y0a7b6AZdNbl2.jpg`} />
                </div>
                <h4>{`How to use the Golden Rain Scanner`}</h4>
                <button className="play-btn" onClick={() => setModalMode("video")}>
                  Watch Now
                </button>
                {modalMode === "video" && (
                  <VideoModal
                    onCancel={() => setModalMode(undefined)}
                    src={`https://player.vimeo.com/video/902379486`}
                    width={1200}
                    isVimeo
                  />
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} lg={12} xl={12} xxl={8}>
              <div className="stvid-card">
                <div className="img-wrap">
                  <img src={`https://mdc-assets.s3.us-east-2.amazonaws.com/resources/akashs-spanish.png`} />
                </div>
                <h4>{`Golden Rain Training(Spanish)`}</h4>
                <button className="play-btn" onClick={() => setModalMode("sp-video")}>
                  Watch Now
                </button>
                {modalMode === "sp-video" && (
                  <VideoModal
                    onCancel={() => setModalMode(undefined)}
                    src={`https://player.vimeo.com/video/917560344`}
                    width={1200}
                    isVimeo
                  />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ height: "24px" }} />
          <DisclaimerBox />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .upgrade-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    font-size: 16px;
  }
  .stvid-card {
    border-radius: 20px;
    border: 1px solid ${props => props.theme.palette.cardBorder};
    background: ${props => props.theme.palette.cardBg};
    padding: 20px;
    .img-wrap {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 14px;
      }
    }
    h4 {
      color: ${props => props.theme.palette.text};
      font-size: 18px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    p {
      color: ${props => props.theme.palette.secondaryText};
      font-size: 14px;
      line-height: 1.5em;
      height: 84px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .play-btn {
      border-radius: 8px !important;
      border: 1px solid ${props => props.theme.palette.submitBtnBg};
      background: ${props => props.theme.palette.submitBtnBg};
      color: ${props => props.theme.palette.submitBtnText};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
      text-transform: capitalize;
      padding: 12px 34px;
    }
  }
`;
