import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Layout, Drawer, ErrorNotification } from "components/common";
import {
  isAuthedUser,
  getUser,
  getSudo,
  saveStorage,
  getToken,
} from "utils/auth";
import { callGetApiWithAuth } from "utils/api";
import { hasPageAccess } from "common/menu";
import BaseLayout from "../base/BaseLayout";
import TopHeader from "./TopHeader";
import Sidebar from "./Sidebar";

const { Header, Content } = Layout;

export default function AuthedLayout({ children, fullLayout, pathname }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useSelector(state => state.ui.theme)
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const isCollapse = useSelector((state) => state.ui.isCollapse);
  const errorMessage = useSelector((state) => state.api.errorMessage);
  const needReload = useSelector((state) => state.auth.needReload);
  const orderError = useSelector((state) => state.checkout.orderError);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onGetProfile = (res) => {
    dispatch({ type: "auth.RELOAD_DONE" });
    saveStorage(res.data, getToken(), getSudo());
    dispatch({
      type: "auth.SET_USER",
      payload: {
        user: res.data,
        sudoName: getSudo(),
      },
    });
  };
  const onFailProfile = () => {
    dispatch({ type: "auth.RELOAD_DONE" });
  };

  const onGetCurrency = (res) => {
    if (res.data) {
      dispatch({
        type: "common.SET_CURRENCY",
        payload: {
          currency: res.data,
        },
      });
    }
  };

  useEffect(() => {
    if (!isAuthedUser()) {
      history.push(
        `/logout?pathName=${window.location.pathname}${window.location.search}`
      );
      return;
    }

    if (
      history?.location?.pathname === "/publisher" ||
      history?.location?.pathname === "/viewer"
    ) {
      dispatch({
        type: "ui.SET_COLLAPSE",
        payload: {
          isCollapse: true,
        },
      });
    } else {
      dispatch({
        type: "ui.SET_COLLAPSE",
        payload: {
          isCollapse: false,
        },
      });
    }
    const ele = document.getElementById('pageContent');
    if (ele) {
      ele.scrollTop = ele.scrollHeight;
      ele.scrollTop = 0;
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (errorMessage) {
      ErrorNotification(errorMessage);
      dispatch({
        type: "api.CLEAR_ERROR_MESSAGE",
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    setTimeout(() => {
       callGetApiWithAuth(`common/currency`, onGetCurrency);
       callGetApiWithAuth(`akashx/common/profile`, onGetProfile, onFailProfile);   
    }, 1500);
  }, []);

  useEffect(() => {
    if (needReload) {
      callGetApiWithAuth(`akashx/common/profile`, onGetProfile, onFailProfile);
    }
  }, [needReload]);

  useEffect(() => {
    // Check Page Access
    if (myUser && history.location.pathname) {
      if (!hasPageAccess(history.location.pathname, myUser, sudoName)) {
        history.push("/");
      }
    }
  }, [myUser, history.location.pathname]);

  useEffect(() => {
    if (orderError) {
      ErrorNotification(orderError);
      dispatch({
        type: "checkout.CLEAR_ORDER_ERROR",
      });
    }
  }, [orderError]);

  useEffect(() => {
    // if (typeof window !== "undefined") {
    //   if (document.getElementsByTagName("body").length > 0) {
    //     let bodySelector = document.getElementsByTagName("body")[0];
    //     bodySelector.classList.remove("login-page");
    //   }
    // }
    // let script1 = document.createElement("script");
    // let script2 = document.createElement("script");
    // script2.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    // let inlineScript = document.createTextNode(`function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element'); }`);
    // script1.appendChild(inlineScript);
    // document.body.appendChild(script1);
    // document.body.appendChild(script2);
  }, []);

  return myUser ? (
    <BaseLayout>
      <LayoutWrapper>
        <Layout>
          <Sidebar />
          <Layout
            className={`
              site-layout 
              ${isCollapse?'collapse-layout':''} 
              theme-${theme}
            `}
          >
            <Header
              className="site-layout-background"
              style={{ padding: 0, height: "90px" }}
            >
              <TopHeader pathname={pathname} showDrawer={showDrawer} />
            </Header>
            <Content className="site-layout-background">
              <Wrapper className={`pageContent`} id={'pageContent'}>
                {fullLayout ? (
                  <div>{children}</div>
                ) : (
                  <div className={"container"}>{children}</div>
                )}
              </Wrapper>
            </Content>
          </Layout>
        </Layout>
      </LayoutWrapper>
      <Drawer title={null} placement="left" onClose={onClose} visible={visible}>
        <Sidebar width={"256px"} />
      </Drawer>
      <Helmet>
        {/*
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=65b733ca-6f80-4823-830c-482d5fddf522"
        >
          {" "}
        </script>
        */}
        <script>
          {`!function(o,i){window.provesrc&&window.console&&console.error&&console.error("ProveSource is included twice in this page."),provesrc=window.provesrc={dq:[],display:function(){this.dq.push(arguments)}},o._provesrcAsyncInit=function(){provesrc.init({apiKey:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI2MjcwMzhjOTA5Yzk2MTM4NWE3YmI4MjYiLCJpYXQiOjE2NTE1MjE3Mzd9.ptOdYQmfOXyKKt89OJZrcoQoicWWXFFk3V_6bIIQ1Cg",v:"0.0.4"})};var r=i.createElement("script");r.type="text/javascript",r.async=!0,r["ch"+"ar"+"set"]="UTF-8",r.src="https://cdn.provesrc.com/provesrc.js";var e=i.getElementsByTagName("script")[0];e.parentNode.insertBefore(r,e)}(window,document);`}
        </script>
      </Helmet>
    </BaseLayout>
  ) : (
    ""
  );
}

const Wrapper = styled.div`
  padding: 0;
`;
const LayoutWrapper = styled.div`
  height: 100%;
  background: ${props=>props.theme.palette.rootBg};
  padding: 20px;
  .pageContent {
    padding: 20px 20px 20px 8px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #e5e5e580 #1d1d1dc0;
    scrollbar-width: thin !important;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 5px transparent;
      background: #1d1d1dc0;
    }
    &::-webkit-scrollbar-track:hover {
      background: #1d1d1dc0;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e5e580;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e5e5e580;
    }
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .theme-light {
    .pageContent {
      scrollbar-color: #c4c4c4ef #fff;
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-track:hover {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #c4c4c4ef;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #c4c4c4ef;
      }
    }
  }
  .site-layout {
    margin-left: 290px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
  .collapse-layout {
    margin-left: 80px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
  .sidebar-collapsed {
    width: 80px !important;
    transition: width 0.7s;
    transition-timing-function: ease-in-out;
    .ant-menu-item {
      padding: 0px 0px 23px 12px !important;
      margin: 7px 0px;
      svg {
        margin-left: 15px !important;
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0px 0px 23px 12px !important;
      }
      svg {
        margin-left: 15px !important;
      }
    }
  }
  @media (max-width: 900px) {
    .sidebar-wrapper {
      display: none;
    }
    .site-layout {
      margin-left: 0px;
    }
  }
  @media (max-width: 600px) {
    .avatar-icons {
      margin-right: 10px !important;
      width: 20px !important;
    }
    .react-country-flag {
      margin-right: 10px !important;
      font-size: 18px !important;
    }
    .route-title {
      font-size: 17px !important;
    }
    .avatar-box {
      margin-right: 0px !important;
      .user-container {
        .user-title {
          font-size: 10px !important;
          line-height: 30px !important;
        }
        .user-id {
          font-size: 8px !important;
        }
      }
    }
  }
`;
