import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { isAuthedUser } from "utils/auth";
import BaseLayout from "../base/BaseLayout";
import LoginLogoImg from "assets/logo2.png";
import LeftBgImg from "assets/akashx_home_banner.png";
import RightBgImg from "assets/login_right_bg.svg";

export default function AuthLayout({ children }) {
  const history = useHistory();

  const handleLoad = (e) => {
    if (typeof window !== "undefined" && document.getElementById("localize-widget")) {
      document.getElementById("localize-widget").style.display = "none";
    }
  };
  
  useEffect(() => {
    window.addEventListener('load', handleLoad, true);
  
    // Remove the event listener
    return () => {
      window.removeEventListener('load', handleLoad, true);
    };
  }, []);

  useEffect(() => {
    if (isAuthedUser()) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (document.getElementsByTagName("body").length > 0) {
        let bodySelector = document.getElementsByTagName("body")[0];
        bodySelector.classList.add('login-page');
      }
    }
  }, []);

  return (
    <BaseLayout>
      <Wrapper>
        <div className={"left-bg"}>
          <div className="left-logo">
            <img src={LoginLogoImg} alt="left-bg" />
          </div>
        </div>
        <div className={"right-root"}>{children}</div>
      </Wrapper>
    </BaseLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  background: #f8f8f8;
  .left-bg {
    background-image: url("${LeftBgImg}");
    background-color: rgba(6,6,6);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 67%;
    height: 100%;
    position: relative;
    padding: 30px 40px;
    .left-logo {
      width: 130px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .main-logo {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 100%;
      padding-right: 4rem !important;
      padding-left: 4rem !important;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .right-root {
    width: 33%;
    background: white !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${RightBgImg});
    background-position: right;
  }
  @media (max-width: 1000px) {
    .left-bg {
      display: none;
    }
    .right-root {
      width: 100%;
    }
  }
`;
