import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Row, Col, Alert, Spin, DisclaimerBox } from "components/common";

export default function CalculatorPage() {
  const [isLoading, setIsLoading] = useState(false);
  let lotFn = null;
  let profitFn = null;
  let pipFn = null;
  let pptFn = null;
  let ddFn = null;

  useEffect(() => {
    setTimeout(() => {
      lotFn = document.createElement("script");
      lotFn.innerHTML = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg","BottomPaneStyle":"YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==","ButtonStyle":"YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==","ContainerWidth":"665","DefaultInstrument":"EUR-USD","HighlightColor":"#ffff00","IsDisplayTitle":false,"IsShowChartLinks":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"position-size-calculator","ContainerId":"position-size-calculator-423310"});`;
      lotFn.async = true;
      document.body.appendChild(lotFn);

      profitFn = document.createElement("script");
      profitFn.innerHTML = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg","BottomPaneStyle":"YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==","ButtonStyle":"YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==","ContainerWidth":"665","DefaultInstrument":"EUR-USD","HighlightColor":"#ffff00","IsDisplayTitle":false,"IsShowChartLinks":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"profit-calculator","ContainerId":"profit-calculator-744839"});`;
      profitFn.async = true;
      document.body.appendChild(profitFn);

      pipFn = document.createElement("script");
      pipFn.innerHTML = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg","BottomPaneStyle":"YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==","ButtonStyle":"YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==","ContainerWidth":"665","DefaultInstrument":"EUR-USD","HighlightColor":"#ffff00","IsDisplayTitle":false,"IsShowChartLinks":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"pip-value-calculator","ContainerId":"pip-value-calculator-741705"});`;
      pipFn.async = true;
      document.body.appendChild(pipFn);

      pptFn = document.createElement("script");
      pptFn.innerHTML = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg","BottomPaneStyle":"YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==","ButtonStyle":"YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==","ContainerWidth":"665","HighlightColor":"#ffff00","IsDisplayTitle":false,"IsShowEmbedButton":false,"CompactType":"large","Calculator":"pivot-point-calculator","ContainerId":"pivot-point-calculator-521094"});`;
      pptFn.async = true;
      document.body.appendChild(pptFn);

      ddFn = document.createElement("script");
      ddFn.innerHTML = `RemoteCalc({"Url":"https://www.cashbackforex.com", "TopPaneStyle":"YmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KCNmZmYgMjAlLCAjZjVmNWY1IDQ1JSk7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgYm9yZGVyLWJvdHRvbTogbm9uZTsg","BottomPaneStyle":"YmFja2dyb3VuZDogI2YzZjNmMzsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYTsgY29sb3I6IGJsYWNrOw==","ButtonStyle":"YmFja2dyb3VuZDogIzM0MzU0MDsgY29sb3I6IHdoaXRlOyBib3JkZXItcmFkaXVzOiAyMHB4Ow==","TitleStyle":"dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==","TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==","ContainerWidth":"665","HighlightColor":"#ffff00","IsDisplayTitle":false,"IsShowEmbedButton":false,"CompactType":"large","DefaultBalance":"20000","DefaultPeriod":"10","DefaultGain":"2","Calculator":"drawdown-calculator","ContainerId":"drawdown-calculator-429037"});`;
      ddFn.async = true;
      document.body.appendChild(ddFn);
    }, [1500]);

    return () => {
      if (lotFn) {
        document.body.removeChild(lotFn);
      }
      if (profitFn) {
        document.body.removeChild(profitFn);
      }
      if (pipFn) {
        document.body.removeChild(pipFn);
      }
      if (pptFn) {
        document.body.removeChild(pptFn);
      }
      if (ddFn) {
        document.body.removeChild(ddFn);
      }
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <script
          type="text/javascript"
          src="https://www.cashbackforex.com/Content/remote/remote-widgets.js"
        ></script>
      </Helmet>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={8}>
          <div className="calc-card">
            <h2>Lot Size Calculator</h2>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin spinning={true} className={`spinner`} size="large" />
              </div>
            ) : (
              <div
                id="position-size-calculator-423310"
                style={{ height: "480px" }}
                className="lot-size-calc"
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="calc-card">
            <h2>Profit Calculator</h2>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin spinning={true} className={`spinner`} size="large" />
              </div>
            ) : (
              <div id="profit-calculator-744839" style={{ height: "480px" }} />
            )}
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="calc-card">
            <h2>Pip Calculator</h2>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin spinning={true} className={`spinner`} size="large" />
              </div>
            ) : (
              <div
                id="pip-value-calculator-741705"
                style={{ height: "480px" }}
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="calc-card pivot-drawn">
            <h2>Pivot Point Calculator</h2>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin spinning={true} className={`spinner`} size="large" />
              </div>
            ) : (
              <div
                id="pivot-point-calculator-521094"
                style={{ height: "480px", width: "100%" }}
                className="pivot-point-calc"
              />
            )}
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="calc-card pivot-drawn">
            <h2>Drawndown Calculator</h2>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin spinning={true} className={`spinner`} size="large" />
              </div>
            ) : (
              <div
                id="drawdown-calculator-429037"
                style={{ height: "480px", width: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ height: 20 }} />
      <DisclaimerBox />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 70px;
  .calc-card {
    background: #fff;
    border: 1px solid #edeef3;
    box-shadow: 0px 3px 2px 0px #edeef3;
    border-radius: 30px;
    padding: 30px 50px;
    margin-bottom: 15px;
    height: 100%;
    h2 {
      font-size: 24px;
      line-height: 1.5em;
      font-weight: 500;
    }
    > div {
      margin: 0 auto;
    }
  }
  .pivot-drawn-col {
    padding-bottom: 30px;
  }
  .pivot-drawn {
    width: 100%;
  }
  .pivot-point-calc {
    height: 100%;
    margin: 50px !important;
    max-width: 100% !important;
  }

  .calc-widget-content {
    max-width: 100% !important;
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    .calc-card {
      padding: 20px;
      width: 100%;
    }
    .pivot-drawn {
      height: auto;
    }
  }
  .pivot-point-calc-widget-top-pane {
    border-radius: 20px;
  }
  .calc-widget-content {
    border-radius: 30px !important;
  }

  .calc-widget-content .calc-widgit-top-pane {
    border-radius: 30px !important;
  }
`;
