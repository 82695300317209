import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

export default function Select2(props) {
  const [isOpened, setIsOpened] = useState(false)
  const selectRef = useRef();

  return (
    <Wrapper className='select2-root'
      onClick={()=>setIsOpened(!isOpened)}
    >
      <select {...props}>
        {props.options.map(el => (
        <option value={el.value}>
          {el.label}
        </option>
        ))}
      </select>
      <span className='arr-icon'>
        {isOpened
        ? <CaretUpOutlined />
        : <CaretDownOutlined />}
      </span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  select {
    background: ${props=>props.theme.palette.select2Bg};
    border: 1px solid ${props=>props.theme.palette.select2Border};
    border-radius: 10px;
    color: ${props=>props.theme.palette.text};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0 8px;
    padding: 10px 70px 10px 12px;
    appearance: none;
    width: calc(100% - 16px);
  }
  option {
    background-color: ${props=>props.theme.palette.select2Bg};
    position: relative;
    right: 100px;
  }
  .arr-icon {
    position: absolute;
    right: 30px;
    top: 11px;
    color: ${props=>props.theme.palette.select2Arr};
  }
  @media (max-width: 1024px) {
    width: 50%;
    margin-bottom: 8px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 8px;
  }
`
